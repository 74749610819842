/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../atoms/Button';
import SubContainer from '../../atoms/SubContainer';
import SubVisualImg from '../../../assets/img/about/sub-visual.jpg';

function NoticeDetailView({ detail }) {
  return (
    <StyledSubContainer
      isVisible
      title="About"
      subTxt="바능은 창의적인 인재가 모여있는, 항상 새로운 기술에 도전하고,
    현재보다 나은 미래를 위해 바능 구성원들과 함께 만들어가고 있습니다."
      subVisual={SubVisualImg}
      className="sub-support"
    >
      <div className="inner-container__sub">
        <h3>공지사항</h3>
        {/* S::리스트형 게시판 - 상세 */}
        <article className="c-bbs">
          <div className="c-bbs-list c-bbs-list__view">
            <div className="c-bbs-list__view--header">
              <strong className="title">
                {detail?.result?.nttSj ?? '제목이 없습니다.'}
              </strong>
              <ul className="item">
                <li>
                  <i className="ri-user-3-line" />
                  <span>관리자</span>
                </li>
                <li>
                  <i className="ri-calendar-line" />
                  <span>
                    {detail?.result?.frstRegisterPnttm ?? '날짜가 없습니다.'}
                  </span>
                </li>
                <li>
                  <i className="ri-eye-line" />
                  <span>923</span>
                </li>
              </ul>
            </div>
            <div className="c-bbs-list__view--body">
              <div
                className="view-content"
                dangerouslySetInnerHTML={{ __html: detail?.result?.nttCn }}
              />
              {/* <dl className="attachment">
                <dt>첨부파일</dt>
                <dd>
                  <ul>
                    <li>
                      <Link to="#">바능 홈페이지가 새롭게 리뉴얼.hwp</Link>
                    </li>
                  </ul>
                </dd>
              </dl> */}
            </div>
          </div>
          <div className="c-bbs__comment">
            <div className="comment-total">
              댓글 <strong>10</strong>개
            </div>
            <form id="commontForm" name="commontForm" className="commont-form">
              <fieldset>
                <legend>댓글등록</legend>
                <div className="commont-form__item">
                  <span>
                    <label htmlFor="writer" className="sr-only">
                      작성자
                    </label>
                    <input
                      type="text"
                      id="writer"
                      name="writer"
                      placeholder="작성자"
                    />
                  </span>
                  <span>
                    <label htmlFor="password" className="sr-only">
                      비밀번호
                    </label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      placeholder="비밀번호"
                    />
                  </span>
                  <Button type="submit" size="sm">
                    등록
                  </Button>
                </div>
                <div className="commont-form__item">
                  <label htmlFor="questions" className="sr-only">
                    문의사항
                  </label>
                  <textarea
                    id="questions"
                    name="questions"
                    rows="5"
                    placeholder=""
                  />
                </div>
              </fieldset>
            </form>
            <div className="commont-list">
              <div className="commont-list__item commont-list__item--noitem">
                등록된 댓글이 없습니다.
              </div>
              <div className="commont__item">
                <div className="item-info">
                  <div className="item-info__cont">
                    <span>
                      <i className="ri-user-3-line" /> 최고관리자
                    </span>
                    <time>2023-05-26 15:41</time>
                  </div>
                  <div className="item-info__btn">
                    <Button size="xs" variant="default">
                      수정
                    </Button>
                    <Button size="xs" variant="error">
                      삭제
                    </Button>
                  </div>
                </div>
                <div className="item-cont">
                  바능 홈페이지가 새롭게 리뉴얼 바능 홈페이지가 새롭게 리뉴얼
                  바능 홈페이지가 새롭게 리뉴얼 바능 홈페이지가 새롭게 리뉴얼
                  바능 홈페이지가 새롭게 리뉴얼 바능 홈페이지가 새롭게 리뉴얼
                </div>
              </div>
              <CommentWrap
                className="commont-list__item commont-list__item--depth1"
                depthValue="1"
              >
                <div className="item-info">
                  <div className="item-info__cont">
                    <span>
                      <i className="ri-user-3-line" /> 최고관리자
                    </span>
                    <time>2023-05-26 15:41</time>
                  </div>
                  <div className="item-info__btn">
                    <Button size="xs" variant="default">
                      수정
                    </Button>
                    <Button size="xs" variant="error">
                      삭제
                    </Button>
                  </div>
                </div>
                <div className="item-cont">
                  바능 홈페이지가 새롭게 리뉴얼 바능 홈페이지가 새롭게 리뉴얼
                  바능 홈페이지가 새롭게 리뉴얼 바능 홈페이지가 새롭게 리뉴얼
                  바능 홈페이지가 새롭게 리뉴얼 바능 홈페이지가 새롭게 리뉴얼
                </div>
              </CommentWrap>
              <CommentWrap
                className="commont-list__item commont-list__item--depth2"
                depthValue="2"
              >
                <div className="item-info">
                  <div className="item-info__cont">
                    <span>
                      <i className="ri-user-3-line" /> 최고관리자
                    </span>
                    <time>2023-05-26 15:41</time>
                  </div>
                  <div className="item-info__btn">
                    <Button size="xs" variant="default">
                      수정
                    </Button>
                    <Button size="xs" variant="error">
                      삭제
                    </Button>
                  </div>
                </div>
                <div className="item-cont">
                  바능 홈페이지가 새롭게 리뉴얼 바능 홈페이지가 새롭게 리뉴얼
                  바능 홈페이지가 새롭게 리뉴얼 바능 홈페이지가 새롭게 리뉴얼
                  바능 홈페이지가 새롭게 리뉴얼 바능 홈페이지가 새롭게 리뉴얼
                </div>
              </CommentWrap>
              <div className="commont-list__item">
                <div className="item-info">
                  <div className="item-info__cont">
                    <span>
                      <i className="ri-user-3-line" /> 최고관리자
                    </span>
                    <time>2023-05-26 15:41</time>
                  </div>
                  <div className="item-info__btn">
                    <Button size="xs" variant="default">
                      수정
                    </Button>
                    <Button size="xs" variant="error">
                      삭제
                    </Button>
                  </div>
                </div>
                <div className="item-cont">
                  바능 홈페이지가 새롭게 리뉴얼 바능 홈페이지가 새롭게 리뉴얼
                  바능 홈페이지가 새롭게 리뉴얼 바능 홈페이지가 새롭게 리뉴얼
                  바능 홈페이지가 새롭게 리뉴얼 바능 홈페이지가 새롭게 리뉴얼
                  바능 홈페이지가 새롭게 리뉴얼 바능 홈페이지가 새롭게 리뉴얼
                  바능 홈페이지가 새롭게 리뉴얼 바능 홈페이지가 새롭게 리뉴얼
                  바능 홈페이지가 새롭게 리뉴얼 바능 홈페이지가 새롭게 리뉴얼
                </div>
              </div>
            </div>
          </div>
          <ul className="c-bbs__nav">
            <li className="nav--prev">
              <Link to="#">
                <span>
                  <i className="ri-arrow-left-line" />
                  이전글
                </span>
                <strong>바능 홈페이지가 새롭게 리뉴얼</strong>
              </Link>
            </li>
            <li className="nav--next">
              <Link to="#">
                <span>
                  <i className="ri-arrow-right-line" />
                  다음글
                </span>
                <strong>바능 캐치펀 베타 서비스 오픈</strong>
              </Link>
            </li>
          </ul>
          <div className="c-bbs__btn">
            <Button href="/notice/" variant="info">
              목록
            </Button>
          </div>
        </article>
        {/* E::리스트형 게시판 - 상세 */}
      </div>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled(SubContainer)`
  h3 {
    text-align: center;
  }
  .c-bbs {
    &-list {
      /* 상세페이지 */
      &__view {
        &--header {
          padding-bottom: 2.4rem;
          border-bottom: 2px solid var(--color-black);
          .title {
            font-size: 2.6rem;
            font-weight: 500;
            & + .item {
              margin-top: 1rem;
            }
          }
          .item {
            display: flex;
            color: var(--color-gray-737373);
            li {
              display: flex;
              align-items: center;
              position: relative;
              & + li {
                margin-left: 1.6rem;
                padding-left: 1.6rem;
                &::before {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 1px;
                  height: 14px;
                  border-left: 1px solid var(--color-gray-d9d9d9);
                }
              }
              i + span {
                margin-left: 0.5rem;
              }
            }
          }
        }
        &--body {
          padding: 4.5rem 3.2rem;
          border-bottom: 1px solid var(--color-gray-d9d9d9);
          /* 컨텐츠 영역 */
          .view-content {
            font-size: 1.7rem;
          }
          /* 첨부파일 */
          .attachment {
            display: flex;
            margin-top: 4rem;
            dt {
              flex: 0 0 10rem;
              padding-top: 1rem;
            }
            dd {
              ul {
                display: flex;
                flex-wrap: wrap;
                li {
                  margin: 0.4rem;
                  a {
                    display: inline-block;
                    padding: 0.5rem 1.6rem;
                    color: var(--color-black);
                    border-radius: 4px;
                    background: var(--color-gray-eaeaea);
                    &:hover {
                      text-decoration: underline;
                      text-underline-position: under;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    /* 댓글 */
    &__comment {
      margin-top: 3.2rem;
      .comment-total {
        margin-bottom: 1rem;
        strong {
          color: var(--color-main);
        }
      }
      .commont-form {
        margin-bottom: 1.6rem;
        &__item {
          & + .commont-form__item {
            margin-top: 0.8rem;
          }
          span + span,
          span + button {
            margin-left: 0.8rem;
          }
          textarea {
            width: 100%;
          }
        }
      }
      .commont-list {
        padding: 2rem;
        background: var(--color-gray-f7f7f7);
        &__item {
          position: relative;
          &:not(.commont-list__item--noitem) {
            padding-bottom: 2rem;
            margin-bottom: 2rem;
            border-bottom: 1px dashed var(--color-gray-d9d9d9);
          }
          &:last-child {
            margin-bottom: 0;
          }
          &--noitem {
            text-align: center;
            padding: 10rem 0;
          }
          /* ${props => `
              &--depth${props.depthValue} {
              padding-left: 2rem;
              &::before,
              &::after {
                content: '';
                position: absolute;
                left: 6px;
                display: inline-block;
                background: var(--color-gray-969696);
              }
              &::before {
                top: 2px;
                width: 1px;
                height: 8px;
              }
              &::after {
                top: 10px;
                width: 8px;
                height: 1px;
              }
            }
          `}
          &--depth1 {
            padding-left: 2rem;
            &::before,
            &::after {
              content: '';
              position: absolute;
              left: 6px;
              display: inline-block;
              background: var(--color-gray-969696);
            }
            &::before {
              top: 2px;
              width: 1px;
              height: 8px;
            }
            &::after {
              top: 10px;
              width: 8px;
              height: 1px;
            }
          }
          &--depth2 {
            padding-left: 3rem;
            &::before,
            &::after {
              content: '';
              position: absolute;
              left: 16px;
              display: inline-block;
              background: var(--color-gray-969696);
            }
            &::before {
              top: 2px;
              width: 1px;
              height: 8px;
            }
            &::after {
              top: 10px;
              width: 8px;
              height: 1px;
            }
          } */
          .item-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &__cont {
              span + time {
                margin-left: 1rem;
              }
            }
            &__btn {
              button + button {
                margin-left: 0.8rem;
              }
            }
          }
          .item-cont {
            margin-top: 0.8rem;
          }
        }
      }
    }
    /* 이전 다음 nav */
    &__nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 2.4rem;
      li {
        width: calc(50% - 2rem);
        &.nav {
          &--prev {
          }
          &--next {
            a {
              justify-content: flex-end;
              span {
                text-align: right;
                order: 2;
                i {
                  float: right;
                }
              }
            }
          }
        }
        a {
          display: flex;
          align-items: center;
          padding: 1.6rem 0;
          color: var(--color-gray-737373);
          font-size: 1.6rem;
          span {
            flex: 0 0 9rem;
          }
          strong {
            color: var(--color-black);
            font-weight: 500;
            ${({ theme }) => theme.text.textOverflow}
          }
        }
      }
    }
    /* 버튼영역 */
    &__btn {
      text-align: center;
    }
  }
  @media ${({ theme }) => theme.media.xl} {
  }
  @media ${({ theme }) => theme.media.lg} {
  }
  @media ${({ theme }) => theme.media.md} {
    .c-bbs {
      &-list {
        /* 상세페이지 */
        &__view {
          &--body {
            padding-right: 0;
            padding-left: 0;
            /* 첨부파일 */
            .attachment {
              flex-direction: column;
              dt {
                flex: 1;
              }
            }
          }
        }
      }
    }
    .c-bbs {
      /* 이전 다음 nav */
      &__nav {
        li {
          a {
            strong {
              display: none;
            }
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.sm} {
  }
`;

const CommentWrap = styled.div`
  ${props => `
      padding-left: ${(parseInt(props.depthValue, 10) + 1).toString()}rem;
      &::before,
      &::after {
        content: '';
        position: absolute;
        left: ${
          parseInt(props.depthValue, 10) - 1 < 1
            ? ''
            : (parseInt(props.depthValue, 10) - 1).toString()
        }6px;
        display: inline-block;
        background: var(--color-gray-969696);
      }
      &::before {
        top: 2px;
        width: 1px;
        height: 8px;
      }
      &::after {
        top: 10px;
        width: 8px;
        height: 1px;
      }
    }
  `}
`;

NoticeDetailView.propTypes = {
  detail: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
};

NoticeDetailView.defaultProps = {
  detail: {},
};

export default NoticeDetailView;
