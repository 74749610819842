import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, {
  Autoplay,
  FreeMode,
  Navigation,
  Pagination,
  Thumbs,
} from 'swiper';

const CustomSwiperView = React.forwardRef((props, ref) => {
  const {
    sliders,
    sliderOptions,
    breakpoints,
    setPrev,
    setTotal,
    isTumbs,
    onThumbsSwiper,
  } = props;

  setTotal(sliders?.length ?? 0);

  SwiperCore.use([
    Autoplay,
    FreeMode,
    Navigation,
    Pagination,
    isTumbs && Thumbs,
  ]);

  return (
    <>
      <StyledSwiper
        {...sliderOptions}
        breakpoints={breakpoints}
        onBeforeInit={swiper => {
          ref.current = swiper;
        }}
        onTransitionEnd={swiper => {
          setPrev((swiper?.realIndex || 0) + 1);
        }}
      >
        {sliders?.map(slider => {
          return <SwiperSlide>{slider}</SwiperSlide>;
        })}
      </StyledSwiper>
      {isTumbs && (
        <Swiper
          onSwiper={onThumbsSwiper}
          loop
          watchSlidesProgress
          slidesPerView={3}
          spaceBetween={16}
          breakpoints={{
            768: {
              slidesPerView: 4,
              spaceBetween: 22,
            },
            // 993: {
            //   slidesPerView: 4,
            //   spaceBetween: 25,
            // },
            1201: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
            1401: {
              slidesPerView: 6,
              spaceBetween: 35,
            },
          }}
        >
          {sliders?.map(slider => {
            return <SwiperSlide>{slider}</SwiperSlide>;
          })}
        </Swiper>
      )}
    </>
  );
});

const StyledSwiper = styled(Swiper)``;

CustomSwiperView.propTypes = {
  sliders: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array])),
  sliderOptions: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object])),
  breakpoints: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object])),
  setPrev: PropTypes.func,
  setTotal: PropTypes.func,
  onThumbsSwiper: PropTypes.func,
  isTumbs: PropTypes.bool,
  tumbOptions: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object])),
};

CustomSwiperView.defaultProps = {
  sliders: [],
  sliderOptions: {},
  breakpoints: {},
  setPrev: () => {},
  setTotal: () => {},
  isTumbs: false,
  tumbOptions: {},
  onThumbsSwiper: () => {},
};
export default CustomSwiperView;
