import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  menu: {}, // 선택 된 메뉴
  menuToTree: {}, // 선택 된 메뉴의 1 left tree 구조
  menues: [], // 기본 menues
  menuesToTree: {}, // 트리 menues
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenu: (state, action) => {
      state.menu = action.payload;
    },
    setMenuToTree: (state, action) => {
      state.menuToTree = action.payload;
    },
    setMenues: (state, action) => {
      state.menues = action.payload;
    },
    setMenuesToTree: (state, action) => {
      state.menuesToTree = action.payload;
    },
  },
});

// actions
export const { setMenu, setMenuToTree, setMenues, setMenuesToTree } =
  menuSlice.actions;

// selector
export const menuSelector = state => state.menu;
// selector
export const menuToTreeSelector = state => state.menuToTree;
// selector
export const menuesSelector = state => state.menues;
// selector
export const menuesToTreeSelector = state => state.menuesToTree;

// reducer
export default menuSlice.reducer;
