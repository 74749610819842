/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import styled from 'styled-components';

// import SubContainer from '../../atoms/SubContainer';
// import SubWrapContainer from '../../atoms/SubWrapContainer';
import SubAsideContainer from '../../atoms/SubAsideContainer';
import Button from '../../atoms/Button';
import SubVisualImg from '../../../assets/img/product/sub-visual.jpg';
import JoinLoginIcon from '../../atoms/Icon/JoinLoginIcon';

function LoginView() {
  return (
    <StyledSubContainer
      // title="Login"
      // subTxt=" 홈페이지에 오신 것을 환영합니다. 아이디 비밀번호를 입력하여 주십시오."
      subVisual={SubVisualImg}
    >
      <article className="c-login">
        <form
          id="loginForm"
          name="loginForm"
          className="login-form"
          // onSubmit={onSubmit}
        >
          <fieldset>
            <legend>로그인</legend>
            <div className="login-form__wrap">
              <div className="login-form__img">
                <JoinLoginIcon />
              </div>
              <div className="login-form__cont">
                <div className="login-form__item login-form__item--id">
                  <label htmlFor="id" className="required sr-only">
                    아이디
                  </label>
                  <input
                    type="text"
                    id="id"
                    name="id"
                    // value={id}
                    placeholder="아이디를 입력해 주세요."
                    // onChange={onChangeSubject}
                  />
                </div>
                <div className="login-form__item login-form__item--pw">
                  <label htmlFor="password" className="required sr-only">
                    패스워드
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    // value={password}
                    placeholder="비밀번호를 입력해 주세요."
                    // onChange={onChangeSubject}
                  />
                </div>
                <div className="login-form__save">
                  <span>
                    <input type="checkbox" id="saveId" name="saveId" />
                    <label htmlFor="saveId">아이디 저장</label>
                  </span>
                  <span>
                    <input type="checkbox" id="savePw" />
                    <label htmlFor="savePw">비밀번호 저장</label>
                  </span>
                </div>
                <div className="login-form__btn">
                  <Button type="submit" size="md" variant="default">
                    로그인
                  </Button>
                </div>
                <div className="login-form__btn--etc">
                  <Button href="#" variant="link" size="sm">
                    아이디 찾기
                  </Button>
                  <Button href="#" variant="link" size="sm">
                    비밀번호 찾기
                  </Button>
                  <Button href="#" variant="link" size="sm">
                    회원가입
                  </Button>
                </div>
                {/* <div className="login-sns">
                <p>
                  <span>SNS 로그인</span>
                </p>
                <ul>
                  <li>
                    <Button
                      href="#"
                      variant="link"
                      size="sm"
                      className="twitter"
                    >
                      트위터
                    </Button>
                  </li>
                  <li>
                    <Button
                      href="#"
                      variant="link"
                      size="sm"
                      className="facebook"
                    >
                      페이스북
                    </Button>
                  </li>
                  <li>
                    <Button href="#" variant="link" size="sm" className="kakao">
                      카카오
                    </Button>
                  </li>
                  <li>
                    <Button href="#" variant="link" size="sm" className="naver">
                      네이버
                    </Button>
                  </li>
                </ul>
              </div> */}
              </div>
            </div>
          </fieldset>
        </form>
      </article>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled(SubAsideContainer)`
  h3,
  .info-txt {
    text-align: center;
  }
  .login-form {
    /* max-width: 60rem; 
    margin: 0 auto;*/
    max-width: 100rem;
    margin: 0 auto;
    border: 1px solid var(--color-gray-d9d9d9);
    background: var(--color-white);
    &__wrap {
      display: flex;
    }
    &__img {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 1 45%;
      background: var(--color-gray-F6F8FC);
      svg {
        max-width: 32rem;
        height: auto;
      }
    }
    &__cont {
      flex: 1;
      padding: 6rem;
      .info-txt {
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 4rem;
      }
    }
    &__item {
      & + [class*='__item'] {
        margin-top: 1.6rem;
      }
      > label {
        position: relative;
        display: inline-block;
        color: var(--color-black);
        font-size: 1.8rem;
        font-weight: 600;
        letter-spacing: -0.065em;
        margin-bottom: 1.3rem;
        &.required {
          &::after {
            content: '*';
            position: absolute;
            top: 50%;
            right: -16px;
            transform: translateY(-50%);
            color: var(--color-red-FF3200);
          }
        }
      }
      input:not([type='checkbox']),
      textarea {
        width: 100%;
        padding: 1.6rem;
        font-size: 1.6rem;
      }
      textarea {
        /* height: 25rem; */
        line-height: 1.5;
      }
    }
    &__save {
      margin-top: 1.6rem;
      span {
        & + span {
          margin-left: 1.8rem;
        }
      }
    }
    &__btn {
      margin-top: 2rem;
      button {
        width: 100%;
      }
      &--etc {
        margin-top: 1rem;
        text-align: center;
        a {
          position: relative;
          & + a {
            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 1px;
              height: 14px;
              border-left: 1px solid var(--color-gray-d9d9d9);
            }
          }
        }
      }
    }
  }
  .login-sns {
    margin-top: 2rem;
    text-align: center;
    p {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background: var(--color-gray-d9d9d9);
      }
      span {
        position: relative;
        padding: 0.5rem 1.8rem;
        background: var(--color-white);
        z-index: 1;
      }
    }
    ul {
      display: flex;
      justify-content: center;
    }
  }
  /* ~ 992px */
  @media ${({ theme }) => theme.media.lg} {
    .login-form {
      &__wrap {
        flex-direction: column;
      }
    }
  }
`;

export default LoginView;
