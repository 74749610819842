// 라이브러리
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

// 공통 컴포넌트
import { useQuery } from '../../../hooks/useQuery';
import { SITE_ID, BBS_ID } from '../../../config/constants';

// 페이지
import NoticeDetailView from './NoticeDetailView';

/**
 * 회사소개
 * @returns
 */
function NoticeDetail() {
  const bbsId = BBS_ID['notice'];
  const { noticeid } = useParams();
  const [paging, setPaging] = useState();

  /**
   * API - 상세정보 조회
   */
  const {
    data: detail,
    refetch: refetchDetail,
    dataUpdatedAt,
    // ...restDetailQuery
  } = useQuery(
    '/api/article/find',
    { siteId: SITE_ID, bbsId, nttId: noticeid },
    {
      cacheTime: 0,
      enabled: !!noticeid,
    },
  );

  /**
   * API - 목록 조회
   */
  const {
    comment,
    refetch: refetchList,
    // ...restQuery
  } = useQuery(
    '/api/comment/findAll',
    { bbsId, nttId: noticeid },
    {
      cacheTime: 0,
      enabled: !!noticeid,
      onSuccess: res => {
        if (res) {
          setPaging(res.paginationInfo);
        }
      },
    },
  );

  /*  
      https://api.reacting.kr/api/article/board/find  -- 게시판 유형
      {
        "bbsId": "BBSMSTR_000000000101"
      } 

      https://api.reacting.kr/api/article/findAll  -- 게시판 목록
      {
        "pageIndex": 1,
        "pageSize": 10,
        "searchCnd": "1",
        "searchWrd": "",
        "bbsId": "BBSMSTR_000000000101",
        "siteId": "SITE_000000000000091"
      }
  */

  /*  
      https://api.reacting.kr/api/article/find  -- 게시판 상세 글
      {
        "siteId": "SITE_000000000000091",
        "bbsId": "BBSMSTR_000000000101",
        "nttId": "254"
      }

      https://api.reacting.kr/api/comment/findAll -- 게시판 댓글 
      {
        "bbsId": "BBSMSTR_000000000101",
        "nttId": "254"
      }
  */

  /**
   * props 세팅
   */
  const props = {
    detail,
    noticeid,
  };

  return <NoticeDetailView {...props} />;
}

export default NoticeDetail;
