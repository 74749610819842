import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// import SubContainer from '../../atoms/SubContainer';
import SubAsideContainer from '../../atoms/SubAsideContainer';
import SubVisualImg from '../../../assets/img/support/sub-visual.jpg';

import SearchBar from '../../molecules/SearchBar';
import PageInfo from '../../molecules/PageInfo';
import Collapse from '../../molecules/Collapse';
import Pagination from '../../molecules/Pagination';

function FaqView(props) {
  const { paging } = props;

  return (
    <StyledSubContainer
      // title="Support"
      // subTxt="바능의 다양한 소식을 보실 수 있습니다."
      subVisual={SubVisualImg}
    >
      <div className="inner-container__sub">
        {/* <h3>Faq</h3> */}
        <SearchBar {...props} />
        {/* S::Faq 게시판 - 리스트 */}
        <article className="c-bbs">
          <PageInfo {...props} />
          <Collapse accordian firstRowOpen {...props} />
          <Pagination {...paging} />
        </article>
        {/* E::Faq 게시판 - 리스트 */}
      </div>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled(SubAsideContainer)`
  h3 {
    text-align: center;
  }

  @media ${({ theme }) => theme.media.mdMin} {
    .c-bbs {
      margin-top: 12rem;
      &__total {
        font-size: 1.7rem;
        em {
          color: var(--color-main);
          font-weight: 500;
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.md} {
    .c-bbs {
      margin-top: 6rem;
    }
  }
`;

FaqView.propTypes = {
  paging: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
  searchOption: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ),
  onSearch: PropTypes.func,
};

FaqView.defaultProps = {
  paging: {},
  searchOption: {},
  onSearch: () => {},
};

export default FaqView;
