import React from 'react';

import CustomSwiperView from './CustomSwiperView';

/**
 * Custom Swiper
 * 기본적인 Swiper 컴포넌트 생성
 * @param {*} props props
 * @param {import('react').Ref} 컴포넌트 ref 연결용
 * @returns CustomSwiper Component
 */
const CustomSwiper = React.forwardRef((props, ref) => {
  return <CustomSwiperView ref={ref} {...props} />;
});

export default CustomSwiper;
