import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import CustomSwiper from '../../molecules/CustomSwiper/CustomSwiper';

import MainVisualImg from '../../../assets/img/main/m-visual-img.jpg';
import SampleImg1 from '../../../assets/img/sample/sample-img1.jpg';
import SampleImg2 from '../../../assets/img/sample/sample-img2.jpg';
import SampleImg3 from '../../../assets/img/sample/sample-img3.jpg';
import SampleImg4 from '../../../assets/img/sample/sample-img4.jpg';
import SampleIcon1 from '../../../assets/img/sample/sample-icon1.png';
import SampleIcon2 from '../../../assets/img/sample/sample-icon2.png';
import SampleIcon3 from '../../../assets/img/sample/sample-icon3.png';
import SampleIcon4 from '../../../assets/img/sample/sample-icon4.png';
import SampleIcon5 from '../../../assets/img/sample/sample-icon5.png';
import SampleIcon6 from '../../../assets/img/sample/sample-icon6.png';
import SampleBanner1 from '../../../assets/img/sample/sample-banner1.jpg';
import SampleBanner2 from '../../../assets/img/sample/sample-banner2.jpg';
import SampleBanner3 from '../../../assets/img/sample/sample-banner3.jpg';
import SampleBanner4 from '../../../assets/img/sample/sample-banner4.jpg';
import SampleBanner5 from '../../../assets/img/sample/sample-banner5.jpg';
import SampleBanner6 from '../../../assets/img/sample/sample-banner6.jpg';
import NoticeIcon from '../../../assets/img/sample/notice-icon.png';
import SampleImg5 from '../../../assets/img/sample/sample-img5.jpg';
import SampleImg6 from '../../../assets/img/sample/sample-img6.jpg';
import SampleImg7 from '../../../assets/img/sample/sample-img7.jpg';

function MainView(props) {
  const { popups } = props;
  const swiperRef = useRef();

  const [prev, setPrev] = useState(1);
  const [total, setTotal] = useState(1);

  // 커스텀 스와이프에 전달하기 위한 slider 목록
  // 지구살리기
  const conType1sliders = [
    {
      href: '#',
      img: SampleImg1,
      alt: '푸른숲 가꾸기 캠페인',
      title: '푸른숲 가꾸기 캠페인',
      content:
        '지구를 살리기 위한 푸른숲 가꾸기 캠페인은 2023년 3월 ~ 2025년 3월까지 3년간 전국에서 다양하게',
    },
    {
      href: '#',
      img: SampleImg2,
      alt: '친환경 먹거리 개발',
      title: '친환경 먹거리 개발',
      content:
        '친환경 먹거리 개발을 통해 인간의 삶의 질을 높이고 미래세대에 깨끗한 지구를 물려주기 위해',
    },
    {
      href: '#',
      img: SampleImg3,
      alt: '지구를 생각하는 건축 기술',
      title: '지구를 생각하는 건축 기술',
      content:
        '지구를 생각하는 친환경 자제, 건축공법을 통해 안락하고 편안한 쉼터같은 집을 계획하고 건축합니다.',
    },
    {
      href: '#',
      img: SampleImg4,
      alt: '빅데이터를 통한 지구 살리기',
      title: '빅데이터를 통한 지구 살리기',
      content:
        '다방면의 데이터를 수집하고 인간의 생존에 필요한 다양한 기술 개발 및 미개척지 개발을 통해 좀더 나은 미래를',
    },
  ];
  // 활동소식
  const sliders = [
    {
      href: '#',
      img: SampleImg5,
      alt: '숲을 가꾸는 벌목',
      title: '숲을 가꾸는 벌목',
      content:
        '숲에서 벌목은 재료용 및 숲을 더욱더 생기있게 만들기 위함도 있다. 이는 숲을 더 푸르고 울창하게 하기 위해서 죽은 나무들을 베어낸다.',
    },
    {
      href: '#',
      img: SampleImg6,
      alt: '친환경 푸드',
      title: '친환경 푸드',
      content:
        '숲에서 벌목은 재료용 및 숲을 더욱더 생기있게 만들기 위함도 있다. 이는 숲을 더 푸르고 울창하게 하기 위해서 죽은 나무들을 베어낸다.',
    },
    {
      href: '#',
      img: SampleImg7,
      alt: '자연과 함께하는 건축',
      title: '자연과 함께하는 건축',
      content:
        '도심속에서 자연을 가까이 함으로써 인간의 삶과 푸르른 지구를 위한 프로젝트로서 전세계적으로 Save Earth 프로젝트를 진행하고 있습니다.',
    },
    {
      href: '#',
      img: SampleImg5,
      alt: '숲을 가꾸는 벌목',
      title: '숲을 가꾸는 벌목',
      content:
        '숲에서 벌목은 재료용 및 숲을 더욱더 생기있게 만들기 위함도 있다. 이는 숲을 더 푸르고 울창하게 하기 위해서 죽은 나무들을 베어낸다.',
    },
    {
      href: '#',
      img: SampleImg6,
      alt: '친환경 푸드',
      title: '친환경 푸드',
      content:
        '숲에서 벌목은 재료용 및 숲을 더욱더 생기있게 만들기 위함도 있다. 이는 숲을 더 푸르고 울창하게 하기 위해서 죽은 나무들을 베어낸다.',
    },
    {
      href: '#',
      img: SampleImg7,
      alt: '자연과 함께하는 건축',
      title: '자연과 함께하는 건축',
      content:
        '도심속에서 자연을 가까이 함으로써 인간의 삶과 푸르른 지구를 위한 프로젝트로서 전세계적으로 Save Earth 프로젝트를 진행하고 있습니다.',
    },
  ];

  return (
    <MainContainer id="content">
      <div className="c-visual">
        <div>
          <img src={MainVisualImg} alt="" />
        </div>
      </div>
      <div className="container">
        {/* m-con type1 */}
        <div className="m-con m-con--type1">
          <div className="con-box">
            <h3>지구 살리기</h3>
            <CustomSwiper
              ref={swiperRef}
              {...{
                sliders: conType1sliders.map(s => {
                  return (
                    <Link to={s.href} className="item">
                      <div className="thumb">
                        <div>
                          <img src={s.img} alt={s.alt} />
                        </div>
                      </div>
                      {s?.content && (
                        <div className="desc">
                          <strong>{s.title}</strong>
                          <span>{s.content}</span>
                        </div>
                      )}
                    </Link>
                  );
                }),
                sliderOptions: {
                  // autoplay: { delay: 2500, disableOnInteraction: false },
                  pagination: true,
                },
                breakpoints: {
                  577: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  993: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  1201: {
                    slidesPerView: 4,
                    spaceBetween: 44,
                  },
                },
                setPrev,
                setTotal,
              }}
            />
            <Link to="#" className="btn-more">
              <i className="ri-add-fill" /> 더보기
            </Link>
          </div>
        </div>
        {/* m-con type2 */}
        <div className="m-con m-con--type2">
          <div className="con-box">
            <h3>분야별 정보</h3>
            <ul className="con__list">
              <li className="list-item item--type1">
                <Link to="#">
                  {/* <span className="icon1" /> */}
                  <strong>숲가꾸기</strong>
                </Link>
              </li>
              <li className="list-item item--type2">
                <Link to="#">
                  <strong>친환경 식품</strong>
                </Link>
              </li>
              <li className="list-item item--type3">
                <Link to="#">
                  <strong>건설</strong>
                </Link>
              </li>
              <li className="list-item item--type4">
                <Link to="#">
                  <strong>교통</strong>
                </Link>
              </li>
              <li className="list-item item--type5">
                <Link to="#">
                  <strong>지구환경</strong>
                </Link>
              </li>
              <li className="list-item item--type6">
                <Link to="#">
                  <strong>IT (과학)</strong>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* m-con type3 */}
        <div className="m-con m-con--type3">
          <div className="con-box box--type1">
            <h3>활동소식</h3>
            <div className="swiper__control">
              <button
                type="button"
                onClick={() => swiperRef.current?.slidePrev()}
                className="swiper-button-prev"
              >
                <span className="sr-only">이전</span>
              </button>
              <div className="swiper-pagination swiper-pagination-fraction">
                <span className="swiper-pagination-current">
                  {`${prev}`.padStart(2, '0')}
                </span>
                <div className="swiper-pagination swiper-pagination-progressbar swiper-pagination-horizontal">
                  <span
                    className="swiper-pagination-progressbar-fill"
                    style={{
                      transform:
                        'translate3d(0px, 0px, 0px) scaleX(0.222222) scaleY(1); transition-duration: 300ms',
                    }}
                  />
                </div>
                <span className="swiper-pagination-total">
                  {`${total}`.padStart(2, '0')}
                </span>
              </div>
              <button
                type="button"
                onClick={() => swiperRef.current?.slideNext()}
                className="swiper-button-next"
              >
                <span className="sr-only">다음</span>
              </button>
            </div>
            <CustomSwiper
              ref={swiperRef}
              {...{
                sliders: sliders.map(s => {
                  return (
                    <Link to={s.href} className="item">
                      <div className="thumb">
                        <div>
                          <img src={s.img} alt={s.alt} />
                        </div>
                      </div>
                      <div className="desc-box">
                        {s?.content && (
                          <div className="desc">
                            <strong>{s.title}</strong>
                            <span>{s.content}</span>
                          </div>
                        )}
                      </div>
                    </Link>
                  );
                }),
                sliderOptions: {
                  // autoplay: { delay: 2500, disableOnInteraction: false },
                },
                breakpoints: {
                  577: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  993: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                },
                setPrev,
                setTotal,
              }}
            />
          </div>
          <div className="con-box box--type2">
            <h3 className="sr-only">배너영역1</h3>
            <ul className="banner__list">
              <li>
                <Link to="#">
                  <img
                    src={SampleBanner1}
                    alt="나무 가꾸기 - 자신의 나무를 키워보세요."
                  />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <img
                    src={SampleBanner2}
                    alt="먹거리 여행 - 전국 맛집찾아 삼만리"
                  />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <img
                    src={SampleBanner3}
                    alt="지하철 여행 - 지하철을 이용한 지구사랑"
                  />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* m-con type4 */}
        <div className="m-con m-con--type4">
          <div className="con-box box--type1">
            <h3 className="h3">공지사항</h3>
            <Link to="#" className="headline">
              SE 프로젝트가 전세계 100개국에서 3월 4일 부터 시작됩니다.
            </Link>
            <ul className="con__list">
              <li>
                <Link to="#">SE 프로젝트 일정 안내</Link>
                <span className="date">2023. 02. 27</span>
              </li>
              <li>
                <Link to="#">
                  새학기를 맞이하여 바능에서 준비한 친환경의류를 할인
                  판매합니다. 새학기를 맞이하여 바능에서 준비한 친환경의류를
                  할인 판매합니다.
                </Link>
                <span className="date">2023. 02. 20</span>
              </li>
              <li>
                <Link to="#">전주 먹거리 여행 투어 일정 변경 안내</Link>
                <span className="date">2023. 02. 19</span>
              </li>
              <li>
                <Link to="#">지하철 이용 프로젝트 캐쉬백 일정 안내</Link>
                <span className="date">2023. 02. 16</span>
              </li>
              <li>
                <Link to="#">
                  나무가꾸기 프로젝트가 강원도 고성군에서 3월 6일 부터
                  시작합니다.
                </Link>
                <span className="date">2023. 02. 07</span>
              </li>
            </ul>
            <Link to="#" className="btn-more">
              <i className="ri-add-fill" /> 더보기
            </Link>
          </div>
          <div className="con-box box--type2">
            {/* S::tab board */}
            <div className="tab-board">
              <div className="tab-title tab1 active">
                <Link to="#" title="선택됨">
                  <span>환경뉴스</span>
                </Link>
              </div>
              <div className="tab-content con1 active">
                <ul className="item-list">
                  <li>
                    <Link to="#">
                      <div className="thumb">
                        <div>
                          <img src={SampleImg3} alt="" />
                        </div>
                      </div>
                      <div className="desc">
                        <strong>친환경 먹거리 박람회</strong>
                        <span className="date">2023.03.01</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="thumb">
                        <div>
                          <img src={SampleImg3} alt="" />
                        </div>
                      </div>
                      <div className="desc">
                        <strong>
                          친환경 프린팅 직물 판매 친환경 프린팅 직물 판매
                        </strong>
                        <span className="date">2023.03.01</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="thumb">
                        <div>
                          <img src={SampleImg3} alt="" />
                        </div>
                      </div>
                      <div className="desc">
                        <strong>벌목을 통한 숲 가꾸기</strong>
                        <span className="date">2023.03.02</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="thumb">
                        <div>
                          <img src={SampleImg3} alt="" />
                        </div>
                      </div>
                      <div className="desc">
                        <strong>지구 살리기 프로젝트</strong>
                        <span className="date">2023.03.03</span>
                      </div>
                    </Link>
                  </li>
                </ul>
                <Link to="#" className="btn-more">
                  <i className="ri-add-fill" />
                  <span className="sr-only">환경뉴스</span> 더보기
                </Link>
              </div>
              <div className="tab-title tab2">
                <Link to="#">
                  <span>보도자료</span>
                </Link>
              </div>
              <div className="tab-content con2">
                <ul className="item-list">
                  <li>
                    <Link to="#">보도자료</Link>
                  </li>
                </ul>
                <Link to="#" className="btn-more">
                  <i className="ri-add-fill" />
                  <span className="sr-only">보도자료</span> 더보기
                </Link>
              </div>
              <div className="tab-title tab3">
                <Link to="#">
                  <span>지구자료실</span>
                </Link>
              </div>
              <div className="tab-content con3">
                <ul className="item-list">
                  <li>
                    <Link to="#">지구자료실</Link>
                  </li>
                </ul>
                <Link to="#" className="btn-more">
                  <i className="ri-add-fill" />
                  <span className="sr-only">지구자료실</span> 더보기
                </Link>
              </div>
            </div>
            {/* E::tab board */}
          </div>
        </div>
        {/* m-con type5 */}
        <div className="m-con m-con--type5">
          <div className="con-box box--type1">
            <h3 className="sr-only">배너영역2</h3>
            <ul className="banner__list">
              <li>
                <Link to="#">
                  <img
                    src={SampleBanner4}
                    alt="나무 가꾸기 - 자신의 나무를 키워보세요."
                  />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <img
                    src={SampleBanner5}
                    alt="먹거리 여행 - 전국 맛집찾아 삼만리"
                  />
                </Link>
              </li>
            </ul>
          </div>
          <div className="con-box box--type2">
            <h3 className="sr-only">배너영역3</h3>
            <Link to="#">
              <img
                src={SampleBanner6}
                alt="지하철 여행 - 지하철을 이용한 지구사랑"
              />
            </Link>
          </div>
        </div>
      </div>
    </MainContainer>
  );
}

const MainContainer = styled.section`
  overflow-x: hidden;
  .container {
    max-width: var(--cantainer-width);
    margin: 0 auto;
    padding: 0 2.4rem;
  }
  .con-box {
    position: relative;
  }
  /* 메인 visual */
  .c-visual {
    position: relative;
    height: 0;
    padding-bottom: 34.367%;
    overflow: hidden;
    > div {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      img {
        transform: scale(1);
        -webkit-transition: all 0.35s ease;
        transition: all 0.35s ease;
        width: 100%;
        max-width: 100%;
        margin: auto;
        display: block;
        object-fit: cover;
      }
    }
    /* 993px ~ */
    @media ${({ theme }) => theme.media.lgMin} {
      margin-bottom: 8rem;
    }
    /* 769px ~ 992px */
    @media ${({ theme }) => theme.media.mdMinlg} {
      margin-bottom: 6rem;
    }
    /* ~ 768px */
    @media ${({ theme }) => theme.media.md} {
      margin-bottom: 4rem;
      > div {
        img {
          height: 100%;
        }
      }
    }
    /* 577px ~ 768px */
    @media ${({ theme }) => theme.media.smMinmd} {
      padding-bottom: 40%;
    }
    /* ~ 576px */
    @media ${({ theme }) => theme.media.sm} {
      padding-bottom: 45%;
    }
  }
  .m-con {
    position: relative;
    h3 {
      margin-bottom: 2.4rem;
      font-size: 3.2rem;
      font-weight: 600;
      &.h3 {
        font-size: 2.4rem;
      }
    }
    /* 지구살리기 */
    &--type1 {
      background: #eff4ff;
      /* swiper-slide */
      .swiper {
        .item {
          &:hover {
            .thumb {
              img {
                transform: scale(1.2);
              }
            }
          }
          .thumb {
            position: relative;
            height: 0;
            padding-bottom: 80%;
            margin-bottom: 2rem;
            overflow: hidden;
            > div {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              overflow: hidden;
              img {
                display: block;
                height: 100%;
                width: 100%;
                margin: auto;
                transform: scale(1);
                transition: all 0.35s ease;
                object-fit: cover;
              }
            }
          }
          .desc {
            strong {
              display: block;
              margin-bottom: 1.4rem;
              font-size: 1.8rem;
              font-weight: 600;
              ${({ theme }) => theme.text.textOverflow}
            }
            span {
              display: -webkit-box;
              font-size: 1.4rem;
              word-wrap: break-word;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
          }
        }
      }
      .btn-more {
        position: absolute;
        top: 2rem;
        right: 0;
        i {
          vertical-align: text-top;
        }
      }
    }
    /* 분야별 정보 */
    &--type2 {
      .con__list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-around;
        gap: 2rem;
        li {
          a {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: 1px solid #ebedf0;
            border-radius: 1.4rem;
            background: #ebedf0;
            transition: all 0.35s ease;
            &:hover {
              border-color: var(--color-black);
              border-radius: 0rem;
              background: var(--color-white);
              box-shadow: 5px 5px 10px var(--color-black-rgba-10);
            }
            strong {
              font-weight: 500;
            }
          }
          &[class*='--type'] {
            a {
              &::before {
                content: '';
                display: inline-block;
                background-repeat: no-repeat;
                background-position: center;
              }
            }
          }
          &[class*='--type1'] {
            a {
              &::before {
                background-image: url(${SampleIcon1});
              }
            }
          }
          &[class*='--type2'] {
            a {
              &::before {
                background-image: url(${SampleIcon2});
              }
            }
          }
          &[class*='--type3'] {
            a {
              &::before {
                background-image: url(${SampleIcon3});
              }
            }
          }
          &[class*='--type4'] {
            a {
              &::before {
                background-image: url(${SampleIcon4});
              }
            }
          }
          &[class*='--type5'] {
            a {
              &::before {
                background-image: url(${SampleIcon5});
              }
            }
          }
          &[class*='--type6'] {
            a {
              &::before {
                width: 16rem;
                background-image: url(${SampleIcon6});
                background-size: 16rem;
              }
            }
          }
        }
      }
      /* 1200px ~  */
      @media ${({ theme }) => theme.media.xlMin} {
        .con__list {
          li {
            flex: 1;
            &[class*='--type'] {
              a {
                padding: 2.4rem 1rem;
                &::before {
                  width: 14rem;
                  height: 14rem;
                  background-size: 14rem;
                }
                strong {
                  margin-top: 1.8rem;
                  font-size: 2.2rem;
                }
              }
            }
          }
        }
      }
      /* 993px ~ 1200px */
      @media ${({ theme }) => theme.media.lgMinxl} {
        .con__list {
          li {
            flex: 1;
            &[class*='--type'] {
              a {
                padding: 1.6rem 1rem;
                &::before {
                  width: 7rem;
                  height: 7rem;
                  background-size: 7rem;
                }
                strong {
                  margin-top: 1.4rem;
                  font-size: 1.9rem;
                }
              }
            }
          }
        }
      }
      /* ~ 992px */
      @media ${({ theme }) => theme.media.lg} {
        .con__list {
          li {
            flex: 0 0 calc(100% / 3 - 1.4rem);
            &[class*='--type'] {
              a {
                padding: 1.6rem 1rem;
                &::before {
                  width: 7rem;
                  height: 7rem;
                  background-size: 7rem;
                }
                strong {
                  margin-top: 1.4rem;
                  font-size: 1.9rem;
                }
              }
            }
          }
        }
      }
    }
    /* 활동소식 & 배너 */
    &--type3 {
      display: flex;
      .box {
        /* 활동소식 */
        &--type1 {
          flex: 1;
          overflow: hidden;
          /* swiper-control */
          .swiper__control {
            display: flex;
            position: absolute;
            top: 1rem;
            right: 0;
            height: 3.2rem;
            .swiper-pagination {
              display: flex;
              align-items: center;
              position: relative;
              top: 0;
              margin: 0 1rem;
              span {
                font-size: 1.6rem;
              }
              &-current,
              &-total {
                font-weight: 700;
              }
              &-total {
                /* color: var(--color-white-rgba-50); */
              }
            }
            .swiper-button-prev,
            .swiper-button-next {
              position: relative;
              top: 0;
              display: inline-block;
              flex: 0 0 3.2rem;
              width: 3.2rem;
              height: 3.2rem;
              margin-top: 0;
              border: 2px solid var(--color-black);
              border-radius: 3px;
              background-color: transparent;
            }
            .swiper-button-prev {
              left: 0;
            }
            .swiper-button-next {
              right: 0;
            }
            .swiper-button-prev:after,
            .swiper-button-next:after {
              font-size: 15px;
              font-weight: 900;
              color: var(--color-black);
            }
            .swiper-pagination-progressbar {
              /* position: absolute; 
              left: 0;
              top: 0;
              width: 100%;*/
              position: relative;
              width: 10rem;
              height: var(--swiper-pagination-progressbar-size, 4px);
              background: var(
                --swiper-pagination-progressbar-bg-color,
                rgba(0, 0, 0, 0.25)
              );
              .swiper-pagination-progressbar-fill {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                transform: scale(0);
                transform-origin: left top;
                background: var(--color-black);
              }
            }
          }
          /* swiper-slide */
          .swiper {
            .item {
              position: relative;
              &:hover {
                .thumb {
                  img {
                    transform: scale(1.1);
                  }
                }
              }
              .thumb {
                position: relative;
                height: 0;
                padding-bottom: 119.34%;
                overflow: hidden;
                > div {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  overflow: hidden;
                  img {
                    display: block;
                    height: 100%;
                    width: 100%;
                    margin: auto;
                    transform: scale(1);
                    transition: all 0.35s ease;
                    object-fit: cover;
                  }
                }
              }
              .desc-box {
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                color: var(--color-white);
                font-size: 2rem;
                font-weight: 500;
                .desc {
                  padding: 2.4rem;
                  background: var(--color-black-rgba-50);
                  strong {
                    display: block;
                    margin-bottom: 1.5rem;
                    font-size: 2.8rem;
                    font-weight: 600;
                    ${({ theme }) => theme.text.textOverflow}
                  }
                  span {
                    display: inline-block;
                    font-size: 1.4rem;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  }
                }
              }
            }
          }
        }
        /* 배너 */
        &--type2 {
          /* flex: 0 0 43.4rem; */
          flex: 1;
          .banner__list {
            display: flex;
            a {
              display: block;
            }
          }
          @media ${({ theme }) => theme.media.xlMin} {
            max-width: 27.17%;
            .banner__list {
              flex-direction: column;
              gap: 2.4rem;
              position: absolute;
              bottom: 0;
            }
          }
          @media ${({ theme }) => theme.media.xl} {
            .banner__list {
              flex-direction: row;
              gap: 2rem;
            }
          }
          @media ${({ theme }) => theme.media.sm} {
            .banner__list {
              flex-direction: column;
              gap: 2rem;
            }
          }
        }
      }
    }
    /* 공지영역 */
    &--type4 {
      display: flex;
      padding: 4rem 4.8rem;
      background: #f2f3f8;
      .box {
        /* 공지사항 */
        &--type1 {
          min-width: 1%;
          h3 {
            padding-bottom: 1.6rem;
            border-bottom: 2px solid var(--color-black);
          }
          .headline {
            display: block;
            padding: 1.6rem 1.6rem 1.6rem 6.2rem;
            font-size: 1.4rem;
            font-weight: 600;
            ${({ theme }) => theme.text.textOverflow}
            background: #dcdcdc url(${NoticeIcon}) no-repeat 1.6rem center /
              3.2rem;
            & + .con__list {
              margin-top: 2rem;
            }
          }
          .con__list {
            li {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 2rem;
              font-size: 1.4rem;
              & + li {
                margin-top: 1.6rem;
              }
              a {
                flex: 1;
                ${({ theme }) => theme.text.textOverflow}
              }
              .date {
                flex: 0 0 10rem;
                text-align: right;
              }
            }
          }
          .btn-more {
            position: absolute;
            top: 1.4rem;
            right: 0;
            font-weight: 500;
            font-family: var(--font-Pretendard);
          }
          /* 1401px ~  */
          @media ${({ theme }) => theme.media.xxlMin} {
            flex: 0 0 36.5%;
          }
          /* 1201px ~ 1400px */
          @media ${({ theme }) => theme.media.xlMinxxl} {
            flex: 0 0 38.5%;
          }
        }
        /* 뉴스:탭공지 */
        &--type2 {
          flex: 1;
          min-width: 1%;
          /* tab board */
          .tab-board {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            &::before {
              content: '';
              position: absolute;
              top: 5.2rem;
              display: inline-block;
              width: 100%;
              border-bottom: 2px solid var(--color-gray-a0a0a0);
            }
            .tab-title {
              &.active {
                a {
                  &::after {
                    content: '';
                    transform: scaleX(1);
                  }
                }
              }
              a {
                position: relative;
                padding: 0 1rem 2rem;
                font-size: 2.4rem;
                font-weight: 600;
                &::after {
                  content: '';
                  display: block;
                  position: absolute;
                  bottom: 0px;
                  left: 0px;
                  right: 0px;
                  height: 0.2rem;
                  transform: scaleX(0);
                  background-color: var(--color-black);
                  transition: all 0.3s ease 0s;
                }
              }
            }
            .tab-content {
              width: 100%;
              margin-top: 4.2rem;
              & + .tab-title {
                margin-left: 2.4rem;
              }
              &:not(.active) {
                display: none;
              }
              .item-list {
                display: flex;
                gap: 2.2rem;
                li {
                  min-width: 1%;
                  a {
                    display: block;
                    background: var(--color-white);
                    transition: all 0.35s ease;
                    &:hover {
                      box-shadow: 5px 5px 10px var(--color-black-rgba-10);
                      .thumb {
                        img {
                          transform: scale(1.1);
                        }
                      }
                    }
                    .thumb {
                      position: relative;
                      height: 0;
                      /* min-height: 15.4rem; */
                      padding-bottom: 72.555%;
                      overflow: hidden;
                      > div {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        overflow: hidden;
                        img {
                          display: block;
                          height: 100%;
                          width: 100%;
                          margin: auto;
                          transform: scale(1);
                          transition: all 0.35s ease;
                          object-fit: cover;
                        }
                      }
                    }
                    .desc {
                      padding: 1.6rem 1.4rem;
                      strong {
                        display: block;
                        margin-bottom: 0.5rem;
                        ${({ theme }) => theme.text.textOverflow}
                      }
                      .date {
                        font-size: 1.3rem;
                        color: #a0a0a0;
                      }
                    }
                  }
                }
              }
              .btn-more {
                position: absolute;
                top: 1.4rem;
                right: 0;
                font-weight: 500;
                font-family: var(--font-Pretendard);
              }
            }
            .tab1 {
              order: 1;
              position: relative;
            }
            .con1 {
              order: 4;
            }
            .tab2 {
              order: 2;
            }
            .con2 {
              order: 5;
            }
            .tab3 {
              order: 3;
            }
            .con3 {
              order: 6;
            }
          }
          /* 1201px ~ 1400px */
          @media ${({ theme }) => theme.media.xlMinxxl} {
            .tab-board {
              &__list {
                li {
                  .tab-content {
                    .item-list {
                      li {
                        &:nth-child(4) {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          /* 1200px ~ */
          @media ${({ theme }) => theme.media.xlMin} {
            .tab-board {
              .tab-content {
                .item-list {
                  li {
                    a {
                      .thumb {
                        min-height: 15.4rem;
                      }
                    }
                  }
                }
              }
            }
          }
          /* 769px ~ */
          @media ${({ theme }) => theme.media.mdMin} {
            .tab-board {
              .item-list {
                li {
                  flex: 1;
                }
              }
            }
          }
          /* ~ 768px */
          @media ${({ theme }) => theme.media.md} {
            .tab-board {
              .item-list {
                flex-wrap: wrap;
                li {
                  flex: 0 0 calc(100% / 2 - 1.1rem);
                }
              }
            }
          }
        }
      }
      /* 1401px ~ */
      @media ${({ theme }) => theme.media.xxlMin} {
        gap: 6rem;
      }
      /* 1201 ~  */
      @media ${({ theme }) => theme.media.xlMin} {
        padding: 4rem 4.8rem;
        gap: 4rem;
      }
      /* ~ 1200 */
      @media ${({ theme }) => theme.media.xl} {
        flex-direction: column;
        gap: 4rem;
        padding: 3rem 3.8rem;
      }
      /* 993px ~ 1400px */
      @media ${({ theme }) => theme.media.lgMinxxl} {
      }
      /* ~ 992px */
      @media ${({ theme }) => theme.media.lg} {
      }
    }
    /* 배너 영역 */
    &--type5 {
      display: flex;
      .box {
        &--type1 {
          .banner__list {
            display: flex;
            gap: 2rem;
          }
          @media ${({ theme }) => theme.media.mdMin} {
            flex: 1;
          }
        }
        &--type2 {
          @media ${({ theme }) => theme.media.mdMin} {
            /* flex: 0 0 77rem; */
            flex: 0 0 48.125%;
          }
        }
      }
      @media ${({ theme }) => theme.media.xxlMin} {
        gap: 4rem;
      }
      @media ${({ theme }) => theme.media.xxl} {
        gap: 2rem;
      }
      @media ${({ theme }) => theme.media.md} {
        flex-direction: column;
      }
    }
  }
  /* 메인 레이아웃 관련 */
  /* 1401px ~ */
  @media ${({ theme }) => theme.media.xxlMin} {
    .m-con {
      /* 활동소식 & 배너 */
      &--type3 {
        gap: 4rem;
      }
    }
  }
  /* ~ 1400px */
  @media ${({ theme }) => theme.media.xxl} {
    .m-con {
      /* 활동소식 & 배너 */
      &--type3 {
        gap: 2.5rem;
      }
    }
  }
  /* 1201px ~  */
  @media ${({ theme }) => theme.media.xlMin} {
    .m-con {
      & + .m-con {
        margin-top: 8rem;
      }
      /* 지구살리기 */
      &--type1 {
        padding: 4rem 4.8rem;
      }
    }
  }
  /* ~ 1200 */
  @media ${({ theme }) => theme.media.xl} {
    .m-con {
      /* 지구살리기 */
      &--type1 {
        padding: 3rem 3.8rem;
        .swiper {
          padding-bottom: 3rem;
          .swiper-pagination {
            bottom: 0;
          }
        }
      }
      /* 활동소식 & 배너 */
      &--type3 {
        flex-direction: column;
      }
    }
  }
  /* 993px ~ */
  @media ${({ theme }) => theme.media.lgMin} {
    margin-bottom: 8rem;
  }
  /* 769px ~ 1200px */
  @media ${({ theme }) => theme.media.mdMinxl} {
    .m-con {
      & + .m-con {
        margin-top: 6rem;
      }
    }
  }
  /* 769px ~ 992px */
  @media ${({ theme }) => theme.media.mdMinlg} {
    margin-bottom: 6rem;
  }
  /* ~ 768px */
  @media ${({ theme }) => theme.media.md} {
    margin-bottom: 4rem;
    .m-con {
      & + .m-con {
        margin-top: 4rem;
      }
    }
  }
`;

MainView.propTypes = {
  popups: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
};

MainView.defaultProps = {
  popups: [],
};

export default MainView;
