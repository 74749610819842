import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import HTMLRenderer from 'react-html-renderer';

import SubContainer from '../../atoms/SubContainer';

function ContentsView({ data }) {
  return (
    <StyledSubContainer id="content">
      <article className="c-contents">
        <HTMLRenderer
          html={data?.content}
          // components={{
          //   h1: props => <Heading color="red" {...props} />,
          //   h2: Subheading,
          //   a: Link,
          // }}
        />
      </article>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled(SubContainer)`
  .sub-inner {
    [class*='__title'] {
      position: absolute;
      overflow: hidden;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      border: 0;
      clip: rect(0, 0, 0, 0);
    }
  }
`;

ContentsView.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
};

ContentsView.defaultProps = {
  data: {},
};

export default ContentsView;
