import React from 'react';
import styled from 'styled-components';

import Button from '../../atoms/Button';
import SubContainer from '../../atoms/SubContainer';
import SubVisualImg from '../../../assets/img/ourWork/sub-visual.jpg';
import LogoCatchfunImg from '../../../assets/img/common/logo-catchfun.png';
import LogoKoreantistImg from '../../../assets/img/common/logo-koreantist.png';
import SkSustainabilityImg from '../../../assets/img/ourWork/sk-sustainability-img.jpg';
import SkSustainabilityImg2 from '../../../assets/img/ourWork/sk-sustainability-img2.png';
import LogoSkHynixImg from '../../../assets/img/ourWork/logo-skhynix.png';
import CmsImg from '../../../assets/img/ourWork/cms-img.jpg';
import CatchfunImg from '../../../assets/img/ourWork/catchfun-img.jpg';
import KoreantistImg from '../../../assets/img/ourWork/koreantist-img.jpg';
import KoreantistGukgaImg from '../../../assets/img/ourWork/koreantist-gukga-img.jpg';
import KoreantistDariImg from '../../../assets/img/ourWork/koreantist-dari-img.jpg';
import KoreantistBaeImg from '../../../assets/img/ourWork/koreantist-bae-img.jpg';
import KoreantistBeolImg from '../../../assets/img/ourWork/koreantist-beol-img.jpg';
import KoreantistBodaImg from '../../../assets/img/ourWork/koreantist-boda-img.jpg';
import KoreantistSinImg from '../../../assets/img/ourWork/koreantist-sin-img.jpg';
import KoreantistSseudaImg from '../../../assets/img/ourWork/koreantist-sseuda-img.jpg';
import KoreantistYeongiImg from '../../../assets/img/ourWork/koreantist-yeongi-img.jpg';

function OurWorkView() {
  return (
    <StyledSubContainer
      isVisible
      title="Our Work"
      subTxt="바능은 최신 기술 트랜드를 기반으로 한 개발 아웃소싱 과 메타버스를 활용한 쿠폰서비스 및 한글의 독창성, 편리성을 전 세계에 알리는 의류 사업을 하고 있습니다."
      subVisual={SubVisualImg}
    >
      <article className="c-si">
        <div className="inner-container__sub">
          <div className="work-img">
            <figure>
              <img
                src={SkSustainabilityImg}
                alt="SK hynix 지속가능성 시스템 사이트"
              />
              <figcaption>Sustainability Reporting System</figcaption>
            </figure>
            <div>
              <dl>
                <dt>2022.03</dt>
                <dd>IT기반의 ESG 플랫폼 구축</dd>
                <dd>반응형(Responsive Web)</dd>
              </dl>
              <img src={SkSustainabilityImg2} alt="" />
            </div>
          </div>
          <div className="work-cont">
            <h3>SI</h3>
            <p className="info-txt">
              고객사가 필요로 하는 정보시스템과 홈페이지, 모바일 서비스의 통합
              구축을 위한 기획, 설계, 개발, 구축, 관리까지 모든 정보 서비스를
              제공합니다. React와 전자정부 프레임워크 기반의 CMS(Contents
              Management System) 솔루션을 보유하고 있습니다.
            </p>
            <div className="c-si__client">
              <img src={LogoSkHynixImg} alt="sk hynix" />
            </div>
            <ul className="c-si__list">
              <li>
                <strong>2022.11</strong>
                <span>Scope3 탄소배출량 산정시스템 구축</span>
              </li>
              <li>
                <strong>2022.08</strong>
                <span>반독점 관리시스템</span>
              </li>
              <li>
                <strong>2022.07</strong>
                <span>
                  환경규제평가 Process 및 Risk Management Modernization
                </span>
              </li>
              <li>
                <strong>2022.05</strong>
                <span>실장기 개발 관리 시스템 구축</span>
              </li>
              <li>
                <strong>2022.04</strong>
                <span>SV Portal 고도화</span>
              </li>
              <li>
                <strong>2022.03</strong>
                <span>IT기반의 ESG 플랫폼 구축</span>
              </li>
              <li>
                <strong>2022.01</strong>
                <span>소통플랫폼</span>
              </li>
              <li>
                <strong>2021.11</strong>
                <span>연구개발 시료관리 3과제 구축&amp;개발</span>
              </li>
              <li>
                <strong>2021.12</strong>
                <span>미기원 요소기술 과제관리 시스템 구축</span>
              </li>
              <li>
                <strong>2021.08</strong>
                <span>산업보안포털 기능 및 구조개선 횡전개</span>
              </li>
              <li>
                <strong>2021.03</strong>
                <span>전사 SV 활동 체계 및 SV Account 확대 구축</span>
              </li>
            </ul>
          </div>
        </div>
      </article>
      <article className="c-cms">
        <div className="inner-container__sub">
          <div className="work-img">
            <figure>
              <img src={CmsImg} alt="" />
            </figure>
          </div>
          <div className="work-cont">
            <h3>
              CMS <span>Contents Management System</span>
            </h3>
            <p className="info-txt">
              웹사이트를 구성하고 있는 다양한 콘텐츠를 효율적으로 관리할 수 있는
              솔루션
            </p>
            <dl className="c-cms__list">
              <dt>Basic CMS</dt>
              <dd>게시판 관리 / 문의하기</dd>
              <dt>Premium CMS</dt>
              <dd>
                사이트 관리 / 권한 관리 / 컨텐츠 관리 / 커뮤니티 관리 / 게시판
                관리 설문 관리 / 채팅
              </dd>
            </dl>
            <div className="c-cms__btn">
              <Button
                href="#"
                size="md"
                variant="default"
                target="_blank"
                title="새창열림"
              >
                CMS 둘러보기 <i className="ri-arrow-right-line" />
              </Button>
            </div>
          </div>
        </div>
      </article>
      <article className="c-catchfun">
        <div className="inner-container__sub">
          <div className="work-img">
            <figure>
              <img src={CatchfunImg} alt="Catchfun" />
            </figure>
          </div>
          <div className="work-cont">
            <h3>
              <img src={LogoCatchfunImg} alt="Catchfun" />
            </h3>
            <p className="info-txt">
              위치기반 할인쿠폰 앱으로 사업자는 간편한 할인쿠폰 등록, 배포 및
              사용자 통계를 활용할 수 있으며 사용자는 활동범위 내의 할인쿠폰을
              취득, 친구와 쿠폰공유 할 수 있는 지도 할인쿠폰 서비스입니다.
            </p>
          </div>
        </div>
      </article>
      <article className="c-koreantist">
        <div className="inner-container__sub">
          <div className="work-img">
            <figure>
              <img src={KoreantistImg} alt="Koreantist" />
            </figure>
          </div>
          <div className="work-cont">
            <h3>
              <img src={LogoKoreantistImg} alt="Koreantist" />
            </h3>
            <p className="info-txt">
              우리 한글을 해외에 알리기 위해 한글을 사용한 재미있는 시나리오 및
              작품으로 탄생한 브랜드입니다. 의류 및 굿즈 등으로 제작되어
              해외진출 위해 준비중에 있습니다.
            </p>
            <ul className="c-koreantist__list">
              <li>
                <figure>
                  <img src={KoreantistGukgaImg} alt="" />
                  <figcaption className="sr-only">보다</figcaption>
                </figure>
              </li>
              <li>
                <figure>
                  <img src={KoreantistDariImg} alt="" />
                  <figcaption className="sr-only">신</figcaption>
                </figure>
              </li>
              <li>
                <figure>
                  <img src={KoreantistBaeImg} alt="" />
                  <figcaption className="sr-only">쓰다</figcaption>
                </figure>
              </li>
              <li>
                <figure>
                  <img src={KoreantistBeolImg} alt="" />
                  <figcaption className="sr-only">연기</figcaption>
                </figure>
              </li>
              <li>
                <figure>
                  <img src={KoreantistBodaImg} alt="" />
                  <figcaption className="sr-only">국가</figcaption>
                </figure>
              </li>
              <li>
                <figure>
                  <img src={KoreantistSinImg} alt="" />
                  <figcaption className="sr-only">다리</figcaption>
                </figure>
              </li>
              <li>
                <figure>
                  <img src={KoreantistSseudaImg} alt="" />
                  <figcaption className="sr-only">배</figcaption>
                </figure>
              </li>
              <li>
                <figure>
                  <img src={KoreantistYeongiImg} alt="" />
                  <figcaption className="sr-only">벌</figcaption>
                </figure>
              </li>
            </ul>
            <div className="c-koreantist__btn">
              <Button
                href="https://www.instagram.com/koreantist"
                size="md"
                variant="default"
                target="_blank"
                title="새창열림"
              >
                코리안티스트 인스타그램 바로가기{' '}
                <i className="ri-arrow-right-line" />
              </Button>
            </div>
          </div>
        </div>
      </article>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled(SubContainer)`
  [class^='c-'] {
    > div {
      position: relative;
      display: flex;
      justify-content: space-between;
      .work-cont {
        h3 {
          margin-bottom: 2.4rem;
          line-height: 1;
          span {
            color: var(--color-gray-3c3c3c);
            font-size: 2rem;
            font-weight: 400;
          }
        }
        .info-txt {
          font-weight: 500;
        }
      }
    }
  }
  .c-si {
    > div {
      .work-img {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        figure {
          position: relative;
          overflow: hidden;
          figcaption {
            position: absolute;
            display: inline-block;
            width: 25rem;
            color: var(--color-white);
            font-size: 4rem;
            font-weight: 600;
            line-height: 1;
          }
        }
        > div {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          dl {
            margin-bottom: 3rem;
            dt {
              margin-bottom: 1rem;
              color: var(--color-gray-737373);
              font-size: 2.3rem;
              font-family: var(--font-Roboto);
            }
            dd {
              position: relative;
              padding-left: 1rem;
              color: var(--color-black);
              font-size: 1.8rem;
              &::before {
                content: '';
                position: absolute;
                top: 1.1rem;
                left: 0;
                display: inline-block;
                width: 0.4rem;
                height: 0.4rem;
                border-radius: 50%;
                background: var(--color-black);
              }
            }
          }
        }
      }
    }
    &__client {
      margin-top: 4rem;
      img {
        width: 15.6rem;
      }
    }
    &__list {
      margin-top: 2.4rem;
      li {
        position: relative;
        display: flex;
        padding: 0.6rem 0 0.6rem 1.2rem;
        font-size: 1.7rem;
        font-weight: 500;
        &::before {
          content: '';
          position: absolute;
          top: 1.6rem;
          left: 0;
          display: inline-block;
          width: 0.4rem;
          height: 0.4rem;
          border-radius: 50%;
          background: var(--color-black);
        }
        strong {
          display: inline-block;
          font-family: var(--font-Roboto);
          font-weight: 500;
        }
      }
    }
  }
  .c-cms {
    position: relative;
    > div {
      .work-img {
        figure {
          overflow: hidden;
        }
      }
    }
    &__list {
      dt {
        position: relative;
        padding-left: 1.2rem;
        font-size: 2.2rem;
        font-weight: 500;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          display: inline-block;
          width: 0.4rem;
          height: 0.4rem;
          border-radius: 50%;
          background: var(--color-black);
        }
      }
      dd {
        padding-left: 1.2rem;
        font-size: 1.7rem;
        & + dt {
          margin-top: 1.7rem;
        }
      }
    }
    &__btn {
      a {
        text-align: center;
        min-width: 28rem;
      }
    }
  }
  .c-catchfun {
    position: relative;
    > div {
      align-items: center;
    }
  }
  .c-koreantist {
    &__list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap-reverse;
      justify-content: space-between;
      margin: 3.2rem 0;
      li {
        figure {
          margin: 0.5rem 0;
          border: 1px solid var(--color-gray-d9d9d9);
        }
      }
    }
  }

  @media ${({ theme }) => theme.media.xlMin} {
    .c-cms {
      &__list {
        margin: 4.8rem 0;
      }
    }
  }
  @media ${({ theme }) => theme.media.xl} {
    .c-cms {
      > div {
        .work-cont {
          h3 {
            span {
              font-size: 1.7rem;
            }
          }
        }
      }
      &__list {
        margin: 2rem 0;
      }
    }
  }
  @media ${({ theme }) => theme.media.lgMin} {
    .c-si {
      > div {
        .work-cont {
          width: calc(50% - 5.5rem);
        }
        .work-img {
          max-width: 50%;
          > div {
            img {
              max-width: 34rem;
            }
          }
        }
      }
    }
    .c-cms {
      &::before {
        content: '';
        position: absolute;
        top: 8vh;
        bottom: 7.9vh;
        left: 0;
        width: calc(100vw - 13vw);
        background: var(--color-gray-F6F8FC);
        z-index: -2;
      }
      > div {
        align-items: center;
        .work-cont {
          width: calc(50% - 5.5rem);
        }
        .work-img {
          max-width: 50%;
        }
      }
    }
    .c-catchfun {
      &::before {
        content: '';
        position: absolute;
        top: 8vh;
        bottom: 7.9vh;
        right: 0;
        width: calc(100vw - 13vw);
        background: var(--color-gray-F6F8FC);
        z-index: -2;
      }
    }
  }
  @media ${({ theme }) => theme.media.mdMin} {
    [class^='c-'] {
      &:nth-child(even) {
        > div {
          flex-direction: row-reverse;
        }
      }
    }
    .c-si {
      > div {
        .work-img {
          figure {
            max-height: 50rem;
            figcaption {
              bottom: 5rem;
              left: 5rem;
            }
          }
        }
      }
    }
    .c-catchfun {
      > div {
        .work-cont {
          width: calc(50% - 5.5rem);
          h3 {
            margin-bottom: 8rem;
            text-align: center;
          }
        }
        .work-img {
          max-width: 50%;
        }
      }
    }
    .c-koreantist {
      .work-cont {
        width: calc(50% - 5.5rem);
      }
      .work-img {
        max-width: 50%;
      }
      &__list {
        li {
          width: calc(100% / 4 - 1rem);
        }
      }
      &__btn {
        a {
          padding-right: 3rem;
          padding-left: 3rem;
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.lg} {
    .c-si {
      > div {
        flex-direction: column;
        .work-cont {
          margin-top: 2rem;
        }
        .work-img {
          figure {
            height: 280px;
            img {
              width: 100%;
            }
            figcaption {
              bottom: 3.5rem;
              left: 3.5rem;
            }
          }
          > div {
            display: none;
          }
        }
      }
    }
    .c-cms {
      background: var(--color-gray-F6F8FC);
      &:nth-child(even) {
        > div {
          flex-direction: column;
        }
      }
      > div {
        .work-cont {
          margin-top: 2rem;
        }
        .work-img {
          figure {
            position: relative;
            height: 280px;
            img {
              position: absolute;
              top: 33%;
              transform: translateY(-50%);
              width: 100%;
            }
            figcaption {
              bottom: 3.5rem;
              left: 3.5rem;
            }
          }
          > div {
            display: none;
          }
        }
      }
    }
    .c-catchfun {
      background: var(--color-gray-F6F8FC);
    }
  }

  @media ${({ theme }) => theme.media.smMin} {
    .c-si {
      &__list {
        li {
          strong {
            flex: 0 0 10rem;
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.md} {
    .c-catchfun {
      > div {
        flex-direction: column;
        .work-cont {
          margin-top: 2rem;
          h3 {
            max-width: 40rem;
          }
        }
      }
    }
    .c-cms {
      &__btn {
        a {
          width: 100%;
          text-align: center;
          font-size: 1.8rem;
        }
      }
    }
    .c-koreantist {
      > div {
        flex-direction: column;
        .work-cont {
          margin-top: 2rem;
          h3 {
            max-width: 40rem;
          }
        }
      }
      &__list {
        li {
          width: calc(100% / 2 - 0.8rem);
        }
      }
      &__btn {
        a {
          width: 100%;
          text-align: center;
          font-size: 1.8rem;
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.sm} {
    .c-si {
      &__list {
        li {
          flex-direction: column;
          border-bottom: 1px dashed var(--color-gray-eaeaea);
        }
      }
    }
    .c-catchfun {
      > div {
        .work-cont {
          h3 {
            max-width: 30rem;
          }
        }
      }
    }
    .c-koreantist {
      > div {
        .work-cont {
          h3 {
            max-width: 30rem;
          }
        }
      }
    }
  }
`;

export default OurWorkView;
