import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Menu from '../../molecules/Menu/Menu';

import Button from '../../atoms/Button';
import LogoImg from '../../../assets/img/common/logo.png';
import SearchIcon from '../../atoms/Icon/SearchIcon';

/**
 * Header
 * @returns
 */
function SiteHeaderView(props) {
  const { onOpenMenu } = props;
  const [isGnbOpen, setGnb] = useState(false); // 메뉴
  const toggleGnb = () => {
    setGnb(isGnbOpen => !isGnbOpen);
  };
  const [isSearchOpen, setSearch] = useState(false); // 검색
  const toggleSearch = () => {
    setSearch(isSearchOpen => !isSearchOpen);
  };

  return (
    <StyledHeader className="site-layout__header">
      <div className="c-header__util">
        <div className="container">
          <ul className="util-list">
            <li>
              <Link to="#">로그인</Link>
            </li>
            <li>
              <Link to="#">회원가입</Link>
            </li>
          </ul>
        </div>
      </div>
      <div
        className="c-header__nav"
        onMouseLeave={() => {
          setGnb(false);
          onOpenMenu && onOpenMenu(false);
        }}
      >
        <div className="container">
          <h1 className="logo">
            <Link to="#">
              <img src={LogoImg} alt="BANEUNG" />
            </Link>
          </h1>
          <Button
            type="button"
            className="btn-menu--open"
            size="xs"
            variant="link"
            onClick={() => {
              toggleGnb();
              onOpenMenu && onOpenMenu(true);
            }}
          >
            <i className="ri-menu-2-fill" />
            <span className="sr-only">주메뉴 열기</span>
          </Button>
          <nav
            id="gnb"
            className={`gnb ${isGnbOpen ? 'gnb-show' : 'gnb-hide'}`}
          >
            <h2 className="sr-only">주메뉴</h2>
            <div className="gnb-util">
              <div className="gnb-util__header">전체메뉴</div>
              <ul className="gnb-util__list">
                <li>
                  <Link to="#">로그인</Link>
                </li>
                <li>
                  <Link to="#">회원가입</Link>
                </li>
              </ul>
            </div>
            <Menu {...props} />
            {/* <ul className="gnb-list list--depth1">
              <li className="list-item active">
                <Link
                  to="#"
                  onMouseOver={() => {
                    setGnb(true);
                  }}
                >
                  <span>1차메뉴 11</span>
                </Link>
                <div className={`depth2 ${isGnbOpen ? 'open' : ''}`}>
                  <ul className="list--depth2">
                    <li className="has-sub active">
                      <Link to="#">
                        <span>2차메뉴</span>
                      </Link>
                      <div className="depth3">
                        <ul className="list--depth3">
                          <li>
                            <Link to="#">
                              <span>3차메뉴</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span>3차메뉴</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span>3차메뉴</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="has-sub">
                      <Link to="#">
                        <span>2차메뉴</span>
                      </Link>
                      <div className="depth3">
                        <ul className="list--depth3">
                          <li>
                            <Link to="#">
                              <span>3차메뉴</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span>3차메뉴</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <Link to="#">
                        <span>2차메뉴</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <span>2차메뉴</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <span>2차메뉴</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                className="list-item"
                onFocus={() => {
                  setGnb(false);
                }}
              >
                <Link to="#">
                  <span>1차메뉴 22</span>
                </Link>
              </li>
              <li className="list-item">
                <Link to="#">
                  <span>1차메뉴 33</span>
                </Link>
              </li>
              <li className="list-item">
                <Link to="#">
                  <span>1차메뉴 44</span>
                </Link>
              </li>
              <li className="list-item">
                <Link to="#">
                  <span>1차메뉴 55</span>
                </Link>
              </li>
            </ul> */}
            <Button
              type="button"
              className="btn-menu--close"
              size="xs"
              variant="link"
              onClick={() => {
                toggleGnb();
                onOpenMenu && onOpenMenu(false);
              }}
            >
              <i className="ri-close-line" />
              <span className="sr-only">주메뉴 닫기</span>
            </Button>
          </nav>
          <div className="search">
            <Button
              type="button"
              className="btn-search--open"
              size="xs"
              variant="link"
              onClick={() => toggleSearch()}
            >
              <SearchIcon />
              <span className="sr-only">
                {isSearchOpen ? '검색 닫기' : '검색 열기'}
              </span>
            </Button>
            <form
              id="searchForm"
              name="searchForm"
              className={`search__form ${
                isSearchOpen ? 'search-show' : 'search-hide'
              }`}
            >
              <fieldset>
                <legend>통합검색</legend>
                <div className="search__input">
                  <input
                    type="text"
                    id="searchWrd"
                    name="searchWrd"
                    placeholder="검색어를 입력하세요."
                  />
                  <Button
                    type="submit"
                    className="btn-search"
                    size="xs"
                    variant="link"
                  >
                    <SearchIcon />
                    <span className="sr-only">검색</span>
                  </Button>
                </div>
              </fieldset>
            </form>
            <Button
              type="button"
              className="btn-search--close"
              size="xs"
              variant="link"
              onClick={() => toggleSearch()}
            >
              <i className="ri-close-line" />
              <span className="sr-only">닫기</span>
            </Button>
          </div>
        </div>
      </div>
    </StyledHeader>
  );
}

const StyledHeader = styled.header`
  /* header 공통 */
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: var(--cantainer-width);
    height: 100%;
    margin: 0 auto;
    padding: 0 2.4rem;
  }
  .c-header {
    /* 상단 util */
    &__util {
      height: 4.2rem;
      background: #162248;
      .util-list {
        display: flex;
        margin-left: auto;
        li {
          & + li {
            margin-left: 2rem;
          }
          a {
            font-size: 1.4rem;
            color: var(--color-white);
          }
        }
      }
    }
    /* logo, gnb */
    &__nav {
      border-bottom: 1px solid var(--color-gray-eaeaea);
      .container {
        gap: 4rem;
      }
    }
  }
  .logo {
    font-size: 0;
    a {
      img {
        width: 21.8rem;
        vertical-align: top;
      }
    }
  }

  /* search */
  .search {
    &__input {
      display: flex;
      align-items: center;
      margin: 0px auto;
      border-radius: 6px;
      border: 2px solid var(--color-black);
      input {
        flex: 1;
        font-size: 1.6rem;
        border: 0;
        padding: 0 1.6rem;
        background: transparent;
      }
      button {
        display: flex;
        align-items: center;
        padding: 0.8rem 1rem;
      }
    }
  }
  /* 1401px ~ */
  @media ${({ theme }) => theme.media.xxlMin} {
    .search {
      &__input {
        width: 34.5rem;
      }
    }
  }
  /* 1201px ~ */
  @media ${({ theme }) => theme.media.xlMin} {
    .search {
      .btn-search--open,
      .btn-search--close {
        display: none;
      }
    }
  }
  /* 993px ~ */
  @media ${({ theme }) => theme.media.lgMin} {
    .c-header {
      /* logo, gnb */
      &__nav {
        height: 10rem;
      }
    }
    .btn-menu--open,
    .btn-menu--close,
    .gnb-util {
      display: none;
    }
    /* GNB */
    .gnb {
      display: flex;
      justify-content: center;
      flex: 1;
      text-align: center;
      .gnb-list {
        display: flex;
        justify-content: center;
        flex: 1;
      }
      /* 1차메뉴 */
      .list--depth1 {
        > li {
          flex: 1;
          /* &.active {
            .depth2 {
              &.open {
                opacity: 1;
                visibility: visible;
                z-index: 10;
              }
            }
          } */
          > a {
            position: relative;
            display: block;
            padding: 3rem 0;
            font-size: 1.8rem;
            color: var(--color-black);
            font-weight: 500;
            text-align: center;
            transition: all 0.3s ease;
            &:hover {
              color: var(--color-main);
            }
          }
        }
      }
      /* 2차메뉴 */
      .depth2 {
        position: absolute;
        top: 14.2rem;
        left: 0;
        width: 100%;
        padding: 2rem;
        text-align: left;
        background: var(--color-white);
        opacity: 0;
        transition: all 0.4s cubic-bezier(0.59, 0.01, 0.43, 1);
        visibility: hidden;
        overflow: hidden;
        &.open {
          opacity: 1;
          visibility: visible;
          z-index: 10;
        }
        .list--depth2 {
          display: flex;
          gap: 2rem;
          max-width: var(--cantainer-width);
          margin: 0 auto;
          padding: 0 2.4rem;
          > li {
            display: inline-block;
            vertical-align: top;
            flex: 0 0 calc(100% / 5 - 1.6rem);
            > a {
              position: relative;
              display: block;
              margin: 0;
              padding: 1.2rem 2rem;
              color: var(--color-black);
              font-weight: 500;
              border: 1px solid var(--color-gray-eaeaea);
              background: var(--color-gray-f7f7f7);
              transition: all 0.35s ease;
              z-index: 10;
              &:hover {
                color: var(--color-white);
                border-color: var(--color-main);
                background-color: var(--color-main);
              }
            }
            &.has-sub {
              > a {
                &::after {
                  content: '';
                  display: inline-block;
                  position: absolute;
                  top: 45%;
                  right: 2rem;
                  width: 0.6rem;
                  height: 0.6rem;
                  border-left: 2px solid var(--color-gray-969696);
                  border-bottom: 2px solid var(--color-gray-969696);
                  transform: rotate(-45deg) translateY(-50%);
                  vertical-align: top;
                  transition: all 0.3s ease 0s;
                }
                &:hover {
                  &::after {
                    border-left-color: var(--color-white);
                    border-bottom-color: var(--color-white);
                  }
                }
              }
            }
          }
        }
      }
      /* 3차메뉴 */
      .depth3 {
        margin-top: 1rem;
        .list--depth3 {
          > li {
            > a {
              position: relative;
              display: block;
              padding: 0.5rem;
              &::before {
                content: '';
                position: absolute;
                top: 1.4rem;
                display: inline-block;
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background: var(--color-gray-969696);
              }
              span {
                padding-left: 1rem;
              }
            }
          }
        }
      }
    }
  }
  /* ~ 1200px */
  @media ${({ theme }) => theme.media.xl} {
    .search {
      .btn-search--open {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
      &__form {
        position: absolute;
        top: 14.2rem;
        left: 0;
        right: 0;
        transition: all 0.4s cubic-bezier(0.59, 0.01, 0.43, 1);
        &.search-hide {
          opacity: 0;
          visibility: hidden;
          overflow: hidden;
          .search__input {
            display: none;
          }
          & + .btn-search--close {
            display: none;
          }
        }
        &.search-show {
          display: block;
          padding-top: 3rem;
          padding-bottom: 3rem;
          opacity: 1;
          visibility: visible;
          background: var(--color-white);
          z-index: 1;
          & + .btn-search--close {
            position: absolute;
            width: 4rem;
            height: 4rem;
            color: var(--color-white);
            font-size: 3rem;
            padding: 0;
            line-height: 1;
            border-radius: 6px;
            background: var(--color-gray-3c3c3c);
            i {
              vertical-align: top;
            }
          }
          /* &::after {
            content: '';
            position: fixed;
            right: 0;
            bottom: 0;
            left: 0;
            background: var(--color-black-rgba-25);
            z-index: -1;
          } */
        }
      }
    }
  }
  /* 993px ~ 1200px */
  @media ${({ theme }) => theme.media.lgMinxl} {
    .search {
      &__form {
        &.search-show {
          & + .btn-search--close {
            top: 7rem;
            right: 2.4rem;
          }
        }
      }
      &__input {
        width: 46rem;
      }
    }
  }
  /* 577px ~ 992px */
  @media ${({ theme }) => theme.media.smMinlg} {
    .search {
      &__form {
        padding-right: 12rem;
        padding-left: 12rem;
      }
    }
  }
  /* ~ 992px */
  @media ${({ theme }) => theme.media.lg} {
    .c-header {
      /* 상단 util */
      &__util {
        display: none;
      }
      /* logo, gnb */
      &__nav {
        height: 8rem;
      }
    }
    /* GNB */
    .btn-menu--open {
      position: absolute;
      top: 0.2rem;
      right: 0;
      font-size: 3rem;
    }
    .btn-menu--close {
      position: absolute;
      top: 0.2rem;
      right: -0.5rem;
      font-size: 2.5rem;
      line-height: 1;
      i {
        vertical-align: top;
      }
    }
    .gnb {
      visibility: hidden;
      position: fixed;
      top: 0;
      z-index: 50;
      width: 40rem;
      height: 100%;
      background-color: #fff;
      overflow-x: hidden;
      overflow-y: auto;
      transition-property: visibility, right;
      transition-duration: 0.25s;
      transition-timing-function: linear;
      background: var(--color-main);
      &.gnb-hide {
        visibility: hidden;
        right: -100%;
      }
      &.gnb-show {
        visibility: visible;
        right: 0;
      }
      .gnb-util {
        background: var(--color-white);
        [class^='gnb-util'] {
          display: flex;
          align-items: center;
          height: 4.2rem;
          padding: 0 1.6rem;
        }
        &__header {
        }
        &__list {
          justify-content: flex-end;
          margin-left: auto;
          background: #162248;
          li {
            & + li {
              margin-left: 2rem;
            }
            a {
              font-size: 1.3rem;
              color: var(--color-white);
            }
          }
        }
      }
      /* 1차메뉴 */
      .list--depth1 {
        > li {
          &.active {
            > a {
              color: var(--color-main);
              background: var(--color-white);
              & + .depth2 {
                display: block;
              }
            }
          }
          &:not(.active) {
            > a {
              & + .depth2 {
                display: none;
              }
            }
          }
          > a {
            display: block;
            width: 10rem;
            padding: 1.6rem 0;
            color: var(--color-white);
            font-size: 1.6rem;
            font-weight: 500;
            text-align: center;
            border-bottom: 1px solid var(--color-black-rgba-10);
            background: var(--color-main);
            transition: all 0.3s ease;
            &:hover {
              color: var(--color-black);
            }
          }
        }
      }
      /* 2차메뉴 */
      .depth2 {
        overflow-y: auto;
        position: absolute;
        top: 8.4rem;
        left: 10rem;
        right: 0;
        bottom: 0;
        z-index: 1;
        padding: 0 1.6rem;
        background: var(--color-white);
        .list--depth2 {
          margin-top: 0.6rem;
          > li {
            &.active {
              > a {
                color: var(--color-main);
                border-bottom-color: var(--color-main);
              }
            }
            &.has-sub {
              > a {
                &::after {
                  content: '';
                  display: inline-block;
                  position: absolute;
                  top: 45%;
                  right: 0;
                  width: 0.6rem;
                  height: 0.6rem;
                  border-left: 2px solid var(--color-gray-969696);
                  border-bottom: 2px solid var(--color-gray-969696);
                  transform: rotate(-45deg) translateY(-50%);
                  vertical-align: top;
                  transition: all 0.3s ease 0s;
                }
                &:hover {
                  &::after {
                    border-left-color: var(--color-gray-3c3c3c);
                    border-bottom-color: var(--color-gray-3c3c3c);
                  }
                }
              }
              &.active {
                > a {
                  &::after {
                    border-left-color: var(--color-black);
                    border-bottom-color: var(--color-black);
                  }
                }
              }
            }
            > a {
              position: relative;
              display: block;
              margin: 0;
              padding: 1.4rem 0;
              color: var(--color-black);
              font-weight: 500;
              transition: all 0.35s ease;
              z-index: 10;
              border-bottom: 1px solid var(--color-gray-eaeaea);
            }
          }
        }
      }
      /* 3차메뉴 */
      .depth3 {
        padding: 1rem 1.6rem;
        background: var(--color-gray-f5f5f5);
        .list--depth3 {
          > li {
            > a {
              position: relative;
              display: block;
              padding: 0.5rem 0;
              font-size: 1.4rem;
              &::before {
                content: '';
                position: absolute;
                top: 1.4rem;
                display: inline-block;
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background: var(--color-gray-969696);
              }
              span {
                padding-left: 1rem;
              }
            }
          }
        }
      }
    }
    .search {
      .btn-search--open {
        margin-right: 3rem;
      }
      &__form {
        top: 8rem;
        &.search-show {
          & + .btn-search--close {
            top: 2rem;
            right: 5.4rem;
          }
        }
      }
    }
  }
  /* ~ 576px */
  @media ${({ theme }) => theme.media.sm} {
    .search {
      &__form {
        padding-right: 2.4rem;
        padding-left: 2.4rem;
      }
    }
  }
`;

SiteHeaderView.propTypes = {
  onOpenMenu: PropTypes.func.isRequired,
};

export default SiteHeaderView;
