import React, { useState } from 'react';
import { useQuery, useMutation } from '../../../hooks/useQuery';

import ContactUsView from './ContactUsView';
import { SITE_ID } from '../../../config/constants';
/**
 * 플레이그라운드
 * @returns
 */
function ContactUs() {
  const [company, setCompany] = useState('');
  const [content, setContent] = useState('');
  /*
    https://api.reacting.kr/api/ctt/insertCtt

    {
        "contactor": "테스트",
        "email": "test@test.com",
        "company": "테스트 컴페니",
        "phoneNumber": "000-9999-0000",
        "words": "문의 내용 입니다.\n문의 문의",
        "regId": "테스트",
        "siteId": "SITE_000000000000091"
    }
  */

  /**
   * API - 정보저장
   */
  const saveMutation = useMutation('/api/ctt/insertCtt');

  /**
   * 문의하기 처리
   */
  const handleSubmit = event => {
    event.preventDefault();
    const dataValues = {
      company,
      words: content,
    };

    saveMutation.mutate(
      {
        ...dataValues,
        contactor: '손님',
        email: '',
        phoneNumber: '',
        siteId: SITE_ID,
        regId: 'system',
      },
      {
        onSuccess: res => {
          if (res?.status === 200) {
            // Message
            handleReset();
            alert('저장 되었습니다.');
          } else {
            const { status, statusText } = res;
            alert(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ updateMutation : error2', JSON.stringify(error));
        },
        onSettled: async () => {
          // console.log('🚀 ~ updateMutation onSettled!!!');
          // Form Reset
          handleReset();
        },
      },
    );
  };

  const handleChangeCompany = ({ target: { value } }) => setCompany(value);

  const handleChangeContent = ({ target: { value } }) => setContent(value);

  const handleReset = () => {
    setCompany('');
    setContent('');
  };

  const props = {
    company,
    content,
    onChangeSubject: handleChangeCompany,
    onChangeContent: handleChangeContent,
    onSubmit: handleSubmit,
  };

  return <ContactUsView {...props} />;
}

export default ContactUs;
