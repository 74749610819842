import React from 'react';
import styled from 'styled-components';

import SubContainer from '../../atoms/SubContainer';
import SubVisualImg from '../../../assets/img/support/sub-visual.jpg';
import KakaoMap from '../../molecules/KakaoMap';

function LocationView() {
  return (
    <StyledSubContainer
      isVisible
      title="Support"
      subTxt="바능의 다양한 소식을 보실 수 있습니다."
      subVisual={SubVisualImg}
      className="sub-support"
    >
      <article className="c-location">
        <div className="inner-container__sub">
          <h3>오시는길</h3>
          <div className="c-location__map">
            <KakaoMap />
          </div>
          <ul className="c-location__list">
            <li>
              <strong>
                <i className="ri-building-line" /> 주소
              </strong>
              <p>
                경기도 성남시 성남대로 295 대림아크로빌 C동 218호 (주식회사
                바능)
              </p>
            </li>
            <li>
              <strong>
                <i className="ri-mail-send-line" /> 이메일
              </strong>
              <p>admin@reacting.kr </p>
            </li>
          </ul>
        </div>
      </article>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled(SubContainer)`
  h3 {
    text-align: center;
  }
  .c-location {
    &__map {
      min-height: 25rem;
      border: 1px solid var(--color-gray-c8c8c8);
    }
    &__list {
      margin-top: 3.2rem;
      li {
        display: flex;
        flex-direction: column;
        & + li {
          margin-top: 3rem;
          padding-top: 3rem;
          border-top: 1px solid var(--color-gray-d9d9d9);
        }
        strong {
          font-size: 2.4rem;
        }
        p {
          font-size: 2rem;
        }
      }
    }
    @media ${({ theme }) => theme.media.lgMin} {
      .c-location {
        &__list {
          li {
            flex-direction: row;
            align-items: center;
            strong {
              flex: 0 0 12rem;
            }
          }
        }
      }
    }
  }
`;

export default LocationView;
