import React from 'react';

import SubLayoutPublishView from './SubLayoutPublishView';

/**
 * subLayoutPublish
 * @returns
 */
function SubLayoutPublish() {
  return <SubLayoutPublishView />;
}

export default SubLayoutPublish;
