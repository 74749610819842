import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ArrowIcon from '../../assets/img/common/ico-arrow.png';

function SubWrapContainerVeiw({ children, subVisual, ...rest }) {
  // util-share toggle
  const [isShareOpen, setIsShareOpen] = useState(false); // 메뉴의 초기값을 false로 설정
  const shareToggle = () => {
    setIsShareOpen(!isShareOpen);
  };
  // breadcrumb toggle
  const [isBreadcrumb, setIsBreadcrumb] = useState(false);
  const BreadcrumbToggle = () => {
    setIsBreadcrumb(!isBreadcrumb);
  };
  // sub-tab toggle
  const [isSubTab, setIsSubTab] = useState(false);
  const subTabToggle = () => {
    setIsSubTab(!isSubTab);
  };

  return (
    <SubWrapContainer id="container" {...rest}>
      <div
        className="sub-visual"
        style={{ backgroundImage: `url(${subVisual}` }}
      >
        <div className="sub-visual__title">
          <h2>1차 메뉴</h2>
        </div>
      </div>
      {/* S::path */}
      <div className="inner-container">
        <div className="c-path">
          <ul className="breadcrumb">
            <li className="home">
              <Link to="#">
                <i className="ri-home-4-line" />
                <span className="sr-only">메인</span>
              </Link>
            </li>
            <li>
              <button
                type="button"
                className={`breadcrumb__btn ${isBreadcrumb ? 'close' : 'open'}`}
                onClick={BreadcrumbToggle}
              >
                <span>1차 메뉴</span>
                <span className="sr-only">
                  {isBreadcrumb ? '닫기' : '열기'}
                </span>
              </button>
              <ul
                className={`breadcrumb__list ${isBreadcrumb ? 'show' : 'hide'}`}
              >
                <li>
                  <Link to="#">1차 메뉴</Link>
                </li>
                <li>
                  <Link to="#">1차 메뉴</Link>
                </li>
                <li>
                  <Link to="#">1차 메뉴</Link>
                </li>
              </ul>
            </li>
            <li>
              <button
                type="button"
                className={`breadcrumb__btn ${isBreadcrumb ? 'close' : 'open'}`}
                onClick={BreadcrumbToggle}
              >
                <span>2차 메뉴</span>
                <span className="sr-only">
                  {isBreadcrumb ? '닫기' : '열기'}
                </span>
              </button>
              <ul
                className={`breadcrumb__list ${isBreadcrumb ? 'show' : 'hide'}`}
              >
                <li>
                  <Link to="#">2차 메뉴</Link>
                </li>
                <li>
                  <Link to="#">2차 메뉴</Link>
                </li>
                <li>
                  <Link to="#">2차 메뉴</Link>
                </li>
                <li>
                  <Link to="#">2차 메뉴</Link>
                </li>
              </ul>
            </li>
            <li>
              <button
                type="button"
                className={`breadcrumb__btn ${isBreadcrumb ? 'close' : 'open'}`}
                onClick={BreadcrumbToggle}
              >
                <span>3차 메뉴</span>
                <span className="sr-only">{isSubTab ? '닫기' : '열기'}</span>
              </button>
              <ul
                className={`breadcrumb__list ${isBreadcrumb ? 'show' : 'hide'}`}
              >
                <li>
                  <Link to="#">3차 메뉴</Link>
                </li>
                <li>
                  <Link to="#">3차 메뉴</Link>
                </li>
                <li>
                  <Link to="#">3차 메뉴</Link>
                </li>
              </ul>
            </li>
          </ul>
          {/* S::content-util */}
          <div className="util">
            <ul className="util__list">
              <li>
                <button type="button" onClick={() => shareToggle()}>
                  <i className="ri-share-line" />
                  <span className="sr-only">sns share</span>
                </button>
                <ul className={`share__list ${isShareOpen ? 'show' : 'hide'}`}>
                  <li className="list-item facebook">
                    <Link
                      to="#"
                      target="_blank"
                      title="새창연결"
                      rel="noopener noreferrer"
                    >
                      <i className="ri-facebook-fill" />
                      <span className="sr-only">페이스북 공유하기</span>
                    </Link>
                  </li>
                  <li className="list-item twitter">
                    <Link
                      to="#"
                      target="_blank"
                      title="새창연결"
                      rel="noopener noreferrer"
                    >
                      <i className="ri-twitter-fill" />
                      <span className="sr-only">트위터 공유하기</span>
                    </Link>
                  </li>
                  <li className="list-item instagram">
                    <Link
                      to="#"
                      target="_blank"
                      title="새창연결"
                      rel="noopener noreferrer"
                    >
                      <i className="ri-instagram-fill" />
                      <span className="sr-only">인스타그램 공유하기</span>
                    </Link>
                  </li>
                  <li className="list-item kakaotalk">
                    <Link
                      to="#"
                      target="_blank"
                      title="새창연결"
                      rel="noopener noreferrer"
                    >
                      <i className="ri-kakao-talk-fill" />
                      <span className="sr-only">카카오톡 공유하기</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <button type="button">
                  <i className="ri-printer-line" />
                  <span className="sr-only">print</span>
                </button>
              </li>
              <li>
                <button type="button">
                  <i className="ri-links-line" />
                  <span className="sr-only">link copy</span>
                </button>
              </li>
            </ul>
          </div>
          {/* E::content-util */}
        </div>
      </div>
      {/* E::path */}

      {/* S::content-wrap */}
      <div className="content-wrap">
        {/* S::content */}
        <section className="c-content">
          {/* S::content-title */}
          <div className="c-title">
            <h3>인사말2</h3>
          </div>
          {/* E::content-title */}

          {/* S::content */}
          <div id="content">
            <div className="inner-container">
              {/* S::sub-tab */}
              <div className="sub-tab">
                <button
                  type="button"
                  className={`sub-tab__btn ${isSubTab ? 'close' : 'open'}`}
                  onClick={subTabToggle}
                >
                  <span>선택메뉴명 노출</span>
                  <span className="sr-only">{isSubTab ? '닫기' : '열기'}</span>
                </button>
                <ul className={`sub-tab__list ${isSubTab ? 'show' : 'hide'}`}>
                  <li className="active">
                    <Link to="#">서브메뉴(3차)</Link>
                  </li>
                  <li>
                    <Link to="#">서브메뉴(3차)</Link>
                  </li>
                  <li>
                    <Link to="#">서브메뉴</Link>
                  </li>
                </ul>
              </div>
              {/* E::sub-tab */}
              <div className="cont-wrap">{children}</div>
            </div>
            {/* E::content */}
          </div>
        </section>
        {/* E::content */}
      </div>
      {/* S::content-wrap */}
    </SubWrapContainer>
  );
}

const SubWrapContainer = styled.section`
  /* inner-wrap Layout */
  .inner-container {
    max-width: var(--cantainer-width);
    margin: 0 auto;
    padding-right: 1.6rem;
    padding-left: 1.6rem;
  }
  /* title-heading */
  h2 {
    color: var(--color-white);
    font-size: 3.8rem;
  }
  /* sub-visual */
  .sub-visual {
    height: 24rem;
    background-repeat: no-repeat;
    background-position: center;
    [class*='__title'] {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: var(--cantainer-width);
      width: 100%;
      margin: 0 auto;
      padding: 0 1.6rem;
      height: calc(100% - 5.4rem);
      h2 {
        color: var(--color-white);
        font-family: var(--font-GmarketSans);
        line-height: 1;
        text-transform: uppercase;
        animation: txt-blur 1.5s 0.1s both;
        @-webkit-keyframes txt-blur {
          0% {
            filter: blur(12px);
            opacity: 0;
          }
          100% {
            filter: blur(0);
            opacity: 1;
          }
        }
        @keyframes txt-blur {
          0% {
            filter: blur(12px);
            opacity: 0;
          }
          100% {
            filter: blur(0);
            opacity: 1;
          }
        }
      }
      p {
        color: var(--color-white);
        font-size: 2rem;
        line-height: 1.8;
        opacity: 0;
        animation: txt-Up 0.7s 0.5s both;
        @-webkit-keyframes txt-Up {
          0% {
            visibility: visible;
            opacity: 0;
            transform: translate3d(0, 50%, 0);
            filter: blur(8px);
          }
          100% {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            filter: blur(0);
          }
        }
        @keyframes txt-Up {
          0% {
            visibility: visible;
            opacity: 0;
            transform: translate3d(0, 50%, 0);
            filter: blur(8px);
          }
          100% {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            filter: blur(0);
          }
        }
      }
    }
  }
  /* path */
  .c-path {
    display: flex;
    justify-content: space-between;
    height: 5.4rem;
    margin-top: -5.4rem;
    background: var(--color-white);
    .breadcrumb {
      display: flex;
      align-items: center;
      > li {
        position: relative;
        height: 100%;
        font-size: 1.4rem;
        &.home {
          a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 5.4rem;
            height: 100%;
            color: var(--color-white);
            background: var(--color-main);
            i {
              font-size: 2rem;
            }
          }
        }
        button {
          display: block;
          width: 100%;
          height: 100%;
          padding-right: 1.8rem;
          padding-left: 1.8rem;
          text-align: left;
          background-color: transparent;
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 1.4rem;
            transform: translateY(-50%) rotate(90deg);
            display: inline-block;
            width: 1.8rem;
            height: 1.8rem;
            background: url(${ArrowIcon}) no-repeat center / 1.8rem;
            transition: all 0.35s ease;
          }
          &.close {
            &::after {
              transform: translateY(-50%) rotate(270deg);
            }
          }
          span:not(.sr-only) {
            font-size: 1.7rem;
          }
        }
      }
      /* breadcrumb-list */
      &__list {
        position: absolute;
        top: calc(100% + 1px);
        width: 100%;
        padding: 1.2rem 1.6rem;
        display: block;
        visibility: visible;
        opacity: 1;
        transition-property: visibility, opacity;
        transition-duration: 250ms;
        transition-timing-function: ease-in-out;
        background: var(--color-white);
        &.hide {
          visibility: hidden;
          opacity: 0;
        }
        li {
          a {
            display: block;
            padding: 0.6rem 0;
          }
        }
      }
    }
  }
  /* util */
  .util {
    &__list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 1rem;
      li {
        position: relative;
        button {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 4rem;
          height: 4rem;
          font-size: 1.9rem;
          background-color: transparent;
        }
      }
      .share__list {
        position: absolute;
        top: 5rem;
        z-index: 1;
        &.hide {
          display: none;
        }
        > li {
          & + li {
            margin-top: 0.5rem;
          }
          a {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 4rem;
            height: 4rem;
            color: var(--color-white);
            font-size: 2rem;
            border-radius: 100%;
          }
          &.facebook {
            a {
              background: var(--color-facebook);
            }
          }
          &.twitter {
            a {
              background: var(--color-twitter);
            }
          }
          &.kakaotalk {
            a {
              background: var(--color-kakaotalk);
            }
          }
          &.instagram {
            a {
              background: var(--color-instagram);
            }
          }
        }
      }
    }
  }
  /* content-wrap */
  .content-wrap {
    display: flex;
    gap: 6rem;
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  /* contents */
  .c-content {
    flex: 1;
    min-height: 60rem;
  }
  /* title */
  .c-title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2.4rem;
    margin-bottom: 4rem;
    h3 {
      font-size: 3.2rem;
    }
  }

  /* 서브 tab menu */
  .sub-tab {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 4.8rem;
    z-index: 1;
    &__list {
      li {
        &.active {
          a {
            color: var(--color-white);
            border-color: var(--color-main);
            background: var(--color-main);
            span {
              &::after {
                width: 100%;
              }
            }
            &:hover {
              color: var(--color-white);
              border-color: var(--color-main);
            }
          }
        }
        a {
          display: block;
          border: 1px solid var(--color-gray-eaeaea);
          transition: all 0.3s ease 0s;
          background: var(--color-white);
          &:hover {
            color: var(--color-black);
            border-color: var(--color-gray-c8c8c8);
            span {
              &::after {
                width: 100%;
              }
            }
          }
          span {
            position: relative;
            display: inline-block;
            padding: 0 2.4rem;
            &::after {
              content: '';
              position: absolute;
              left: 0;
              bottom: 0;
              display: block;
              width: 0;
              height: 2px;
              background: var(--color-main);
            }
          }
        }
      }
    }
  }

  /* 993px ~ */
  @media ${({ theme }) => theme.media.lgMin} {
    .c-path {
      .util {
        display: flex;
        margin-right: 1.6rem;
      }
      .breadcrumb {
        > li {
          &:not(.home) {
            width: 22rem;
            &::after {
              content: '';
              position: absolute;
              top: 1.4rem;
              right: 0;
              bottom: 1.4rem;
              display: block;
              width: 1px;
              background: var(--color-gray-d9d9d9);
            }
          }
        }
        &__list {
          border: 1px solid var(--color-gray-eaeaea);
        }
      }
    }
  }
  /* ~ 992px */
  @media ${({ theme }) => theme.media.lg} {
    .c-path {
      margin-left: -1.6rem;
      margin-right: -1.6rem;
      border-bottom: 1px solid var(--color-gray-eaeaea);
      .breadcrumb {
        flex: 1;
        > li {
          &:last-child {
            width: 100%;
          }
          &:not(:last-child) {
            display: none;
          }
        }
        &__list {
          border-bottom: 1px solid var(--color-gray-eaeaea);
        }
      }
      .util {
        display: none;
      }
    }
  }
  /* 769px ~ */
  @media ${({ theme }) => theme.media.mdMin} {
    .sub-tab {
      &__list {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 1rem;
        li {
          &.active {
            a {
              border-radius: 0;
            }
          }
          a {
            padding: 1.2rem 2rem;
            border-radius: 0.6rem;
            &:hover {
              border-radius: 0;
            }
          }
        }
      }
      &__btn {
        display: none;
      }
    }
  }
  /* ~ 768px */
  @media ${({ theme }) => theme.media.md} {
    .sub-tab {
      &__list {
        &.hide {
          visibility: hidden;
          opacity: 0;
        }
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        display: block;
        visibility: visible;
        opacity: 1;
        transition-property: visibility, opacity;
        transition-duration: 250ms;
        transition-timing-function: ease-in-out;
        li {
          a {
            position: relative;
            padding: 0.8rem 2rem;
            margin: -1px 0 0 0;
            &:hover {
              border-color: var(--color-gray-c8c8c8);
              z-index: 1;
            }
          }
        }
      }
      &__btn {
        width: 100%;
        padding: 1.2rem 2rem;
        font-size: 1.5rem;
        text-align: left;
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 1.4rem;
          transform: translateY(-50%) rotate(90deg);
          display: inline-block;
          width: 1.8rem;
          height: 1.8rem;
          background: url(${ArrowIcon}) no-repeat center / 1.8rem;
          transition: all 0.35s ease;
        }
        &.close {
          &::after {
            transform: translateY(-50%) rotate(270deg);
          }
        }
      }
    }
  }
`;

SubWrapContainerVeiw.propTypes = {
  children: PropTypes.element,
  subVisual: PropTypes.string,
};

SubWrapContainerVeiw.defaultProps = {
  children: '',
  subVisual: '',
};

export default SubWrapContainerVeiw;
