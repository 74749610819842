// Grid Link Cell Style
export const GRID_LINK_CELL_STYLE = {
  color: '#1890FF',
  // fontWeight: 'bold',
  cursor: 'pointer',
  // whiteSpace: 'normal',
  // lineHeight: '1.5rem',
};

// 사이트 ID는 환경 구성시 설정해야 할 정보이므로
// env에 적용하여 사용 예정.
export const SITE_ID = 'SITE_000000000000001';

// 아래 BBS ID 정보는 가변적인 부분이라 변경 예정
export const BBS_ID = {
  notice: 'BBSMSTR_000000000091',
  support: 'BBSMSTR_000000000101',
  tech: 'BBSMSTR_000000000192',
  guest: 'BBSMSTR_000000000201',
  recruit: 'BBSMSTR_000000000191',
  test: 'BBSMSTR_000000000241',
};
// 'SITE_000000000000001'; playground
// 'SITE_000000000000091'; company

export const SESSION_STORAGE = sessionStorage;
export const LOCAL_STORAGE = localStorage;
export const SEARCH_KEY = 'SearchValue';

export const chattingEndPointUrl = 'http://localhost:8080/chatting';

export const dummyMenues = [
  {
    menuNo: 1,
    menuNm: '1차 1번 메뉴',
    menuDc: '메뉴 설명',
    menuUrl: '/playground/main',
    menuOrder: '1',
    menuImg: '이미지1',
    upperMenuNo: 0,
  },
  {
    menuNo: 2,
    menuNm: '1차 2번 메뉴',
    menuDc: '메뉴 설명',
    menuUrl: '/playground/main',
    menuOrder: '2',
    menuImg: '이미지2',
    upperMenuNo: 0,
  },
  {
    menuNo: 3,
    menuNm: '1차 3번 메뉴',
    menuDc: '메뉴 설명',
    menuUrl: '/playground/main',
    menuOrder: '3',
    menuImg: '이미지3',
    upperMenuNo: 0,
  },
  {
    menuNo: 4,
    menuNm: '1차 4번 메뉴',
    menuDc: '메뉴 설명',
    menuUrl: '/playground/main',
    menuOrder: '4',
    menuImg: '이미지4',
    upperMenuNo: 0,
  },
  {
    menuNo: 5,
    menuNm: '1차 5번 메뉴',
    menuDc: '메뉴 설명',
    menuUrl: '/playground/main',
    menuOrder: '5',
    menuImg: '이미지5',
    upperMenuNo: 0,
  },
  {
    menuNo: 6,
    menuNm: '2차 메뉴 1',
    menuDc: '메뉴 설명',
    menuUrl: '/playground/main',
    menuOrder: '1',
    menuImg: '이미지 1-1',
    upperMenuNo: 1,
  },
  {
    menuNo: 7,
    menuNm: '2차 메뉴 2',
    menuDc: '메뉴 설명',
    menuUrl: '/playground/main',
    menuOrder: '2',
    menuImg: '이미지 1-2',
    upperMenuNo: 1,
  },
  {
    menuNo: 8,
    menuNm: '2차 메뉴 3',
    menuDc: '메뉴 설명',
    menuUrl: '/playground/main',
    menuOrder: '2',
    menuImg: '이미지 1-3',
    upperMenuNo: 1,
  },
  {
    menuNo: 9,
    menuNm: '2차 메뉴 4',
    menuDc: '메뉴 설명',
    menuUrl: '/playground/main',
    menuOrder: '2',
    menuImg: '이미지 1-4',
    upperMenuNo: 1,
  },
  {
    menuNo: 10,
    menuNm: '2차 메뉴 5',
    menuDc: '메뉴 설명',
    menuUrl: '/playground/main',
    menuOrder: '2',
    menuImg: '이미지 1-5',
    upperMenuNo: 1,
  },
  {
    menuNo: 11,
    menuNm: '2차 메뉴 4',
    menuDc: '메뉴 설명',
    menuUrl: '/playground/main',
    menuOrder: '2',
    menuImg: '이미지 1-4',
    upperMenuNo: 2,
  },
  {
    menuNo: 11,
    menuNm: '2차 메뉴 5',
    menuDc: '메뉴 설명',
    menuUrl: '/playground/main',
    menuOrder: '2',
    menuImg: '이미지 1-5',
    upperMenuNo: 2,
  },
  {
    menuNo: 13,
    menuNm: '3차 메뉴 1',
    menuDc: '메뉴 설명',
    menuUrl: '/playground/main',
    menuOrder: '1',
    menuImg: '이미지 1-1-1',
    upperMenuNo: 6,
  },
  {
    menuNo: 14,
    menuNm: '3차 메뉴 2',
    menuDc: '메뉴 설명',
    menuUrl: '/playground/main',
    menuOrder: '1',
    menuImg: '이미지 1-1-2',
    upperMenuNo: 6,
  },
  {
    menuNo: 15,
    menuNm: '3차 메뉴 3',
    menuDc: '메뉴 설명',
    menuUrl: '/playground/main',
    menuOrder: '1',
    menuImg: '이미지 1-1-3',
    upperMenuNo: 6,
  },
  {
    menuNo: 16,
    menuNm: '3차 메뉴 1',
    menuDc: '메뉴 설명',
    menuUrl: '/playground/main',
    menuOrder: '1',
    menuImg: '이미지 1-2-1',
    upperMenuNo: 7,
  },
  {
    menuNo: 17,
    menuNm: '3차 메뉴 2',
    menuDc: '메뉴 설명',
    menuUrl: '/playground/main',
    menuOrder: '1',
    menuImg: '이미지 1-2-2',
    upperMenuNo: 7,
  },
];
