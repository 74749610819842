import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
  setMenu,
  setMenuToTree,
  setMenues,
  setMenuesToTree,
} from '../store/menu/reducer';

import {
  listToTree,
  selectedLeafStructure,
  flattenTree,
} from '../config/utils/util';
import { dummyMenues } from '../config/constants';

/**
 * 메뉴 구성 관련 커스텀 훅
 * @returns menu, menuToTree, menues, menuesToTree를 리턴
 * menu: 선택 된 menu Object
 * menuToTree: 선택 된 menu의 tree 구조 node { node { node {}}}
 * menues: 초기 DB에서 받아온 배열 형태 (제거 예정)
 * menuesToTree: DB에서 받은 1차 배열을 Tree 구조로 변경한 형태
 */
function useMenu() {
  const dispatch = useDispatch();

  /** 메뉴 정보 목록 */
  const menu = useSelector(state => state.menu.menu, shallowEqual);
  const menuToTree = useSelector(state => state.menu.menuToTree, shallowEqual);
  const menues = useSelector(state => state.menu.menues, shallowEqual);
  const menuesToTree = useSelector(
    state => state.menu.menuesToTree,
    shallowEqual,
  );

  /**
   * Tree -> Object { 1(depth): [], 2(depth): [], 3(depth): []}
   * 기본적으로 Tree의 형태가 제공 됨
   * 계산을 용이하게 하기 위한 형태 변경
   * 정상적인 경우: Tree 형태의 메뉴 정보를 DB에서 제공받음.
   */
  const base = useMemo(
    () => selectedLeafStructure(menuesToTree),
    [menuesToTree],
  );

  /**
   * 최초 1회 실행을 위한 설정
   * Menu 트리 정보를 가져온 후 store에 설정
   * 이 함수는 useEffect에서 실행한다.
   */
  const privateFetchMenues = useCallback(() => {
    dispatch(setMenues(dummyMenues));
    const tree = listToTree(dummyMenues);
    tree && dispatch(setMenuesToTree(tree));
  }, [dummyMenues]);

  // redux에 해당 선택된 메뉴 설정
  const handleSelectedMenu = uniqId => {
    if (menues?.length > 0) {
      const menu = flattenTree(menuesToTree)[uniqId] ?? {};
      const menuToTree = handleGetSelectMenuStruct(menu);
      menu && dispatch(setMenu(menu));
      menuToTree && dispatch(setMenuToTree(menuToTree));
    }
  };

  /**
   * tree에서 선택 된 node의 구조를 가져오는 함수
   * depth: menuObject
   * 1: {menu}
   * 2: {menu}
   * 3: {menu}
   * @param {*} m 선택된 메뉴
   * @returns tree 라인
   */
  const handleGetSelectMenuStruct = m => {
    const createMenuObj = m => {
      const menuObj = {};
      menuObj[m.depth] = m;

      if (m.depth > 1) {
        const upper = base[m.depth - 1].filter(
          tm => tm.menuNo === m.upperMenuNo,
        )[0];
        if (upper) {
          const childrenObj = createMenuObj(upper, m);
          Object.assign(menuObj, childrenObj);
        }
      }

      return menuObj;
    };

    return createMenuObj(m);
  };

  useEffect(() => {
    if (menues?.length < 1) {
      privateFetchMenues();
    }
  }, [menues, privateFetchMenues]);

  return {
    menu,
    menuToTree,
    menues,
    menuesToTree,
    menuSelect: handleSelectedMenu,
  };
}

export default useMenu;
