import React from 'react';

import SubLayoutPublish2View from './SubLayoutPublish2View';

/**
 * subLayoutPublish2
 * @returns
 */
function SubLayoutPublish2() {
  return <SubLayoutPublish2View />;
}

export default SubLayoutPublish2;
