import React from 'react';
import styled from 'styled-components';

import mainImg1 from './img/main-img1.jpg';
import mainImg2 from './img/main-img2.jpg';
import mainImg3 from './img/main-img3.jpg';
import mainImg4 from './img/main-img4.jpg';
import footerImg from './img/footer.jpg';

function Images() {
  return (
    <StyledSubContainer>
      <div className="inner">
        <img src={mainImg1} alt="" />
        <img src={mainImg2} alt="" />
        <img src={mainImg3} alt="" />
        <img src={mainImg4} alt="" />
        <img src={footerImg} alt="" />
      </div>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled.div`
  .inner {
    max-width: 1920px;
    margin: 0 auto;
  }
`;

export default Images;
