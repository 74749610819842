import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../atoms/Button';
import SubContainer from '../../atoms/SubContainer';
import KoreantistDariImg from '../../../assets/img/ourWork/koreantist-dari-img.jpg';

function CommunityCafeView() {
  return (
    <StyledSubContainer title="커뮤니티" className="sub-community">
      <div className="inner-container__sub">
        <article className="c-community c-community--cafe">
          <div className="c-community__aside">
            <div className="community__user">
              <div className="item-cont">
                <div className="item-thumb">
                  <img src={KoreantistDariImg} alt="" />
                </div>
                <div className="item-info">
                  <p>
                    <strong>바능</strong>님
                  </p>
                </div>
                <Button type="button" size="sm" variant="normal">
                  글쓰기
                </Button>
              </div>
            </div>
            <div className="community__add">
              <Link to="#">
                <div className="item-thumb">
                  <i className="ri-draft-line" />
                </div>
                <div className="item-info">
                  <strong className="title">신규 게시판 만들기</strong>
                  <div className="info">지금 커뮤니티를 생성해 보세요.</div>
                </div>
              </Link>
            </div>
            <ul className="community__list">
              <li>
                <p>text</p>
                <Button type="button" size="xs" variant="normal">
                  수정
                </Button>
              </li>
              <li>
                <p>방명록</p>
                <Button type="button" size="xs" variant="normal">
                  수정
                </Button>
              </li>
            </ul>
            <ul className="community-nav__list">
              <li>
                <strong>공지안내</strong>
              </li>
              <li>
                <strong>질문과 답변</strong>
                <ul>
                  <li>질문게시판</li>
                  <li>문의게시판</li>
                </ul>
              </li>
              <li>
                <strong>커뮤니티</strong>
                <ul>
                  <li>자유게시판</li>
                  <li>이벤트게시판</li>
                  <li>여행갤러리</li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="c-community__content">
            <ul className="community-item__list">
              <li className="list-item">
                <div className="list-item--header">
                  <strong>test게시판</strong>
                </div>
                <ul className="list-item--list">
                  <li>
                    <Link to="#">게시글 테스트</Link>
                    <time className="date">2021-04-22</time>
                  </li>
                  <li>
                    <Link to="#">방명록 입니다.</Link>
                    <time className="date">2021-04-15</time>
                  </li>
                </ul>
                <Link to="#" className="btn-more">
                  더보기 <i className="ri-add-fill" />
                </Link>
              </li>
              <li className="list-item">
                <div className="list-item--header">
                  <strong>방명록 게시판</strong>
                </div>
                <ul className="list-item--list">
                  <li>
                    <Link to="#">게시글 테스트</Link>
                    <time className="date">2021-04-22</time>
                  </li>
                  <li>
                    <Link to="#">방명록 입니다.</Link>
                    <time className="date">2021-04-15</time>
                  </li>
                  <li>
                    <Link to="#">방명록 입니다.</Link>
                    <time className="date">2021-04-15</time>
                  </li>
                </ul>
                <Link to="#" className="btn-more">
                  더보기 <i className="ri-add-fill" />
                </Link>
              </li>
              <li className="list-item">
                <div className="list-item--header">
                  <strong>갤러리 게시판</strong>
                </div>
                <ul className="list-item--list list--gallery">
                  <li>
                    <Link to="#">
                      <picture>
                        <img src={KoreantistDariImg} alt="" />
                      </picture>
                      <div className="desc">
                        <strong className="title">다리~~~</strong>
                        <time className="date">23-02-14</time>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <picture>
                        <img src={KoreantistDariImg} alt="" />
                      </picture>
                      <div className="desc">
                        <strong className="title">게시글 제목</strong>
                        <time className="date">23-02-14</time>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <picture>
                        <img src={KoreantistDariImg} alt="" />
                      </picture>
                      <div className="desc">
                        <strong className="title">
                          제목제목제목제목제목제목
                        </strong>
                        <time className="date">23-02-14</time>
                      </div>
                    </Link>
                  </li>
                </ul>
                <Link to="#" className="btn-more">
                  더보기 <i className="ri-add-fill" />
                </Link>
              </li>
            </ul>
          </div>
        </article>
      </div>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled(SubContainer)`
  overflow-x: hidden;
  .sub-inner {
    [class*='__title'] {
      position: absolute;
      overflow: hidden;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      border: 0;
      clip: rect(0, 0, 0, 0);
    }
  }
  .c-community {
    display: flex;
    gap: 4rem;
    &__aside {
      flex: 0 1 35rem;
      .community {
        &__user {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .item-cont {
            display: flex;
            flex-direction: row;
            flex: 1;
            gap: 2rem;
            .item-thumb {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              flex: 0 0 6rem;
              width: 6rem;
              height: 6rem;
              border: 1px solid var(--color-gray-eaeaea);
              background: #f9f9f9;
            }
            .item-info {
              display: flex;
              justify-content: space-between;
              flex: 1;
              strong {
                margin-right: 0.5rem;
              }
            }
          }
        }
        &__add {
          a {
            display: flex;
            align-items: center;
            gap: 2rem;
            padding: 2rem;
            border: 1px dashed var(--color-gray-eaeaea);
            background-color: var(--color-gray-f7f7f7);
            transition: all 0.3s ease;
            &:hover {
              border-style: solid;
              border-color: var(--color-black);
              background-color: var(--color-white);
              box-shadow: 3px 3px 10px var(--color-black-rgba-25);
              .item-thumb {
                color: var(--color-black);
                background-color: var(--color-gray-f7f7f7);
              }
            }
            .item-thumb {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              flex: 0 0 6rem;
              width: 6rem;
              height: 6rem;
              background: var(--color-white);
              transition: all 0.3s ease;
              i {
                font-size: 2.5rem;
              }
            }
            .item-info {
              .title {
                color: var(--color-black);
                font-size: 1.7rem;
                font-weight: 500;
              }
            }
          }
        }
        &__list {
          margin: 2rem 0;
          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            & + li {
              padding-top: 1rem;
              margin-top: 1rem;
              border-top: 1px solid var(--color-gray-eaeaea);
            }
          }
        }
        &-nav__list {
          > li {
            & + li {
              margin-top: 1rem;
            }
            strong {
              display: block;
              padding: 1rem 0;
              color: #418087;
              color: var(--color-main);
              font-weight: 500;
              border-bottom: 2px solid var(--color-main);
              & + ul {
                margin-top: 1rem;
              }
            }
            ul {
              padding: 1.4rem;
              background: #f5f8f9;
              background: var(--color-gray-F6F8FC);
              > li {
                & + li {
                  padding-top: 0.5rem;
                  margin-top: 0.5rem;
                }
              }
            }
          }
        }
      }
    }
    &__content {
      flex: 1;
      .community {
        &-item__list {
          display: flex;
          gap: 2rem;
          .list-item {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            padding: 2rem;
            border: 1px solid var(--color-gray-eaeaea);
            background: var(--color-white);
            &--header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-bottom: 1rem;
              border-bottom: 1px solid var(--color-gray-c8c8c8);
              strong {
                display: block;
                max-width: 84%;
                font-size: 1.7rem;
                font-weight: 500;
                ${({ theme }) => theme.text.textOverflow}
              }
            }
            &--list {
              li {
                .date {
                  font-size: 1.4rem;
                  color: var(--color-gray-737373);
                }
              }
              &:not(.list--gallery) {
                li {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  & + li {
                    padding-top: 0.8rem;
                    margin-top: 0.8rem;
                    border-top: 1px dashed var(--color-gray-eaeaea);
                  }
                }
              }
              &.list--gallery {
                display: flex;
                gap: 20px;
                li {
                  position: relative;
                  width: calc((100% - 40px) / 3);
                  a {
                    transition: 0.3s;
                    &:hover {
                      picture {
                        img {
                          transform: scale(1.1);
                        }
                      }
                    }
                    picture {
                      position: relative;
                      display: block;
                      width: 100%;
                      height: 0;
                      padding-bottom: 72%;
                      margin-bottom: 1.6rem;
                      border: 1px solid var(--color-gray-eaeaea);
                      overflow: hidden;
                      img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: 0.5s;
                      }
                    }
                    .desc {
                      .title {
                        display: block;
                        ${({ theme }) => theme.text.textOverflow}
                      }
                    }
                  }
                }
              }
            }
            .btn-more {
              position: absolute;
              top: 2.4rem;
              right: 2rem;
              color: var(--color-gray-737373);
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }

  @media ${({ theme }) => theme.media.xlMin} {
    .c-community {
      &__aside {
        flex: 0 1 35rem;
        .community {
          &__user {
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.xl} {
    .c-community {
      flex-direction: column;
    }
  }
  @media ${({ theme }) => theme.media.mdMinxl} {
    .c-community {
      &__aside {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        .community {
          &__user,
          &__add {
            flex: 0 0 calc(100% / 2 - 1rem);
          }
          &__list,
          &-nav__list {
            flex: 1;
          }
          &__user {
            padding: 0 2rem;
            border: 1px solid var(--color-gray-eaeaea);
          }
        }
      }
    }
  }

  @media ${({ theme }) => theme.media.mdMin} {
    .c-community {
      &__content {
        .community {
          &-item__list {
            flex-direction: row;
            flex-wrap: wrap;
            .list-item {
              flex: 0 0 calc((100% / 2) - 1rem);
              min-width: 1%;
            }
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.md} {
    .c-community {
      &__aside {
        .community {
          &__user {
            margin-bottom: 2rem;
          }
        }
      }
      &__content {
        .community {
          &-item__list {
            flex-direction: column;
          }
        }
      }
    }
  }
`;

CommunityCafeView.defaultProps = {};

export default CommunityCafeView;
