import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import SubContainer from '../../atoms/SubContainer';
import Button from '../../atoms/Button';
import SubVisualImg from '../../../assets/img/product/sub-visual.jpg';

function SurveyView() {
  return (
    <StyledSubContainer
      title="설문조사"
      // subTxt=" 홈페이지에 오신 것을 환영합니다."
      subVisual={SubVisualImg}
      className="sub-survey"
    >
      <div className="inner-container__sub">
        <article className="c-survey">
          <ul className="c-survey__tab">
            <li className="active">
              <Link to="#">전체</Link>
            </li>
            <li>
              <Link to="#">진행중인 설문</Link>
            </li>
            <li>
              <Link to="#">완료된 설문</Link>
            </li>
          </ul>
          <ul className="c-survey__list">
            <li className="list-item--ing">
              {/* <Link to="#"> */}
              <span className="badge badge--ing">진행중</span>
              <div className="title">
                <strong>설문 테스트1</strong>
              </div>
              <ul className="info">
                <li>
                  <strong>설문대상</strong>
                  <span className="target">대상1</span>
                </li>
                <li>
                  <strong>설문기간</strong>
                  <time className="date">2022-04-18 ~ 2022-05-06</time>
                </li>
              </ul>
              <Button type="button" size="md" variant="info" className="block">
                참여하기
              </Button>
              {/* </Link> */}
            </li>
            <li className="list-item--ing">
              <span className="badge badge--ing">진행중</span>
              <div className="title">
                <strong>
                  수요자중심 공공데이터 개방을 위한 공공데이터 개방 선호도 조사
                </strong>
              </div>
              <ul className="info">
                <li>
                  <strong>설문대상</strong>
                  <span className="target">대상1</span>
                </li>
                <li>
                  <strong>설문기간</strong>
                  <time className="date">2022-04-18 ~ 2022-05-06</time>
                </li>
              </ul>
              <Button type="button" size="md" variant="info" className="block">
                참여하기
              </Button>
            </li>
            <li className="list-item--complete">
              <span className="badge badge--complete">완료</span>
              <div className="title">
                <strong>
                  홈페이지 사용자 만족도 설문조사 계획 홈페이지 사용자 만족도
                  설문조사 계획 홈페이지 사용자 만족도 설문조사 계획
                </strong>
              </div>
              <ul className="info">
                <li>
                  <strong>설문대상</strong>
                  <span className="target">대상1</span>
                </li>
                <li>
                  <strong>설문기간</strong>
                  <time className="date">2022-04-18 ~ 2022-05-06</time>
                </li>
              </ul>
              <Button
                type="button"
                size="md"
                variant="info"
                className="block"
                disabled
              >
                참여완료
              </Button>
            </li>
            <li className="list-item--complete">
              <span className="badge badge--complete">완료</span>
              <div className="title">
                <strong>홈페이지 사용자 만족도 설문조사 계획</strong>
              </div>
              <ul className="info">
                <li>
                  <strong>설문대상</strong>
                  <span className="target">고등학생, 대학생, 성인 등</span>
                </li>
                <li>
                  <strong>설문기간</strong>
                  <time className="date">2022-04-18 ~ 2022-05-06</time>
                </li>
              </ul>
              <Button
                type="button"
                size="md"
                variant="info"
                className="block"
                disabled
              >
                참여완료
              </Button>
            </li>
          </ul>
        </article>
      </div>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled(SubContainer)`
  /* &::before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 9.5rem;
    background: #333;
  } */
  h3,
  .info-txt {
    text-align: center;
  }
  .inner-container {
    .info-txt {
      font-size: 2.5rem;
      margin-bottom: 2.4rem;
      strong {
        color: var(--color-main);
      }
    }
    .c-survey {
      &__tab {
        display: flex;
        margin-bottom: 3rem;
        li {
          & + li {
            margin-left: -1px;
          }
          &.active {
            a {
              color: var(--color-white);
              border-color: var(--color-main);
              background-color: var(--color-main);
            }
          }
          a {
            padding: 0.8rem 2rem;
            color: var(--color-black);
            font-size: 1.6rem;
            text-align: center;
            border: 1px solid var(--color-gray-d9d9d9);
          }
        }
      }
      &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        > li {
          position: relative;
          display: block;
          width: calc((100% - 80px) / 3);
          padding: 2.4rem;
          color: var(--color-black);
          border: 1px solid var(--color-gray-eaeaea);
          transition: 0.3s;
          &[class^='list-item'] {
            &[class*='--ing'] {
              &:hover {
                border-color: var(--color-gray-d9d9d9);
              }
            }
            &[class*='--complete'] {
              opacity: 0.75;
              &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                opacity: 0.25;
                background: var(--color-gray-F6F8FC);
              }
            }
          }
          .badge {
            color: #fff;
            padding: 0.4rem 1rem;
            border-radius: 10rem;
            box-shadow: 4px 6px 6px rgba(0, 0, 0, 0.1);
            &--ing {
              background: #ff774d;
            }
            &--complete {
              background: #2abfb8;
              background: #586270;
            }
            & + .title {
              margin-top: 1rem;
            }
          }
          .title {
            display: flex;
            align-items: center;
            margin-bottom: 4rem;
            strong {
              display: -webkit-box;
              height: 6.8rem;
              font-size: 2.4rem;
              font-weight: 600;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              text-overflow: ellipsis;
              overflow: hidden;
              /* ${({ theme }) => theme.text.textLineClamp2} */
            }
          }
          .block {
            width: 100%;
            margin-top: 2rem;
          }
          .info {
            display: flex;
            flex-direction: column;
            padding: 1.6rem;
            margin-top: 1.6rem;
            background: var(--color-gray-f7f7f7);
            li {
              & + li {
                margin-top: 1rem;
              }
              strong {
                position: relative;
                display: inline-block;
                margin-right: 3rem;
                &::after {
                  content: '';
                  position: absolute;
                  right: -1.6rem;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 1px;
                  height: 14px;
                  border-left: 1px solid var(--color-gray-d9d9d9);
                }
              }
              .date {
                color: var(--color-gray-737373);
              }
            }
          }
        }
      }
    }
  }
`;

SurveyView.defaultProps = {};

export default SurveyView;
