import React from 'react';

const SearchIcon = props => {
  return (
    <svg width="25.081" height="25.18" viewBox="0 0 25.081 25.18" {...props}>
      <line x1="6.483" y1="6.497" transform="translate(17.184 17.269)" />
      <line
        x1="6.483"
        y1="6.497"
        transform="translate(17.184 17.269)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <g transform="translate(-3 -2.958)">
        <g>
          <path
            d="M20.174,20.144a9.477,9.477,0,1,1,2.783-6.708,9.582,9.582,0,0,1-.212,2"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

export default SearchIcon;
