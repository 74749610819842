import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import SubVisualImg from '../../assets/img/about/sub-visual.jpg';
import ArrowIcon from '../../assets/img/common/ico-arrow.png';

function SubAsideContainerVeiw({ children, subVisual, ...rest }) {
  // util-share toggle
  const [isShareOpen, setIsShareOpen] = useState(false); // 메뉴의 초기값을 false로 설정
  const shareToggle = () => {
    setIsShareOpen(!isShareOpen);
  };
  // sub-tab toggle
  const [isSubTab, setIsSubTab] = useState(false);
  const subTabToggle = () => {
    setIsSubTab(!isSubTab);
  };

  return (
    <SubContainer id="container" {...rest}>
      <div
        className="sub-visual"
        style={{ backgroundImage: `url(${subVisual}` }}
      >
        <div className="sub-visual__title">
          <h2>1차 메뉴</h2>
        </div>
      </div>
      {/* S::path */}
      <div className="c-path">
        <div className="inner-container">
          <ul className="breadcrumb">
            <li className="home">
              <i className="ri-home-4-line" />
              <span className="sr-only">메인</span>
            </li>
            <li>1차 메뉴</li>
            <li className="active">2차 메뉴</li>
          </ul>
        </div>
      </div>
      {/* E::path */}

      {/* S::content-wrap */}
      <div className="content-wrap inner-container">
        {/* S::aside */}
        <aside className="c-lnb">
          <h2>
            <span>ABOUT</span>
          </h2>
          {/* S::lnb */}
          <nav id="lnb" className="lnb">
            <ul className="lnb-list list--depth1">
              <li className="list-item has-sub active">
                <Link to="#">
                  <span>인사말</span>
                </Link>
                <div className="depth2">
                  <ul className="list--depth2">
                    <li className="list-item active">
                      <Link to="#">
                        <span>2차 메뉴 1</span>
                      </Link>
                    </li>
                    <li className="list-item">
                      <Link to="#">
                        <span>2차 메뉴 2</span>
                      </Link>
                    </li>
                    <li className="list-item">
                      <Link to="#">
                        <span>2차 메뉴 3</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="list-item has-sub">
                <Link to="#">
                  <span>회사연혁</span>
                </Link>
                <div className="depth2">
                  <ul className="list--depth2">
                    <li className="list-item active">
                      <Link to="#">
                        <span>2차 메뉴 1</span>
                      </Link>
                    </li>
                    <li className="list-item">
                      <Link to="#">
                        <span>2차 메뉴 2</span>
                      </Link>
                    </li>
                    <li className="list-item">
                      <Link to="#">
                        <span>2차 메뉴 3</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="list-item">
                <Link to="#">
                  <span>조직도</span>
                </Link>
              </li>
              <li className="list-item has-sub">
                <Link to="#">
                  <span>오시는길</span>
                </Link>
              </li>
            </ul>
          </nav>
          {/* E::lnb */}
        </aside>
        {/* E::aside */}

        {/* S::content */}
        <section className="c-content">
          {/* S::content-title */}
          {/* S::path */}
          <div className="c-path">
            <ul className="breadcrumb">
              <li className="home">
                <i className="ri-home-4-line" />
                <span className="sr-only">메인</span>
              </li>
              <li>1차 메뉴</li>
              <li className="active">2차 메뉴</li>
            </ul>
          </div>
          {/* E::path */}
          <div className="c-title">
            <h3>인사말</h3>
            {/* S::content-util */}
            <div className="util">
              <ul className="util__list">
                <li>
                  <button type="button" onClick={() => shareToggle()}>
                    <i className="ri-share-line" />
                    <span className="sr-only">sns share</span>
                  </button>
                  <ul
                    className={`share__list ${isShareOpen ? 'show' : 'hide'}`}
                  >
                    <li className="list-item facebook">
                      <Link
                        to="#"
                        target="_blank"
                        title="새창연결"
                        rel="noopener noreferrer"
                      >
                        <i className="ri-facebook-fill" />
                        <span className="sr-only">페이스북 공유하기</span>
                      </Link>
                    </li>
                    <li className="list-item twitter">
                      <Link
                        to="#"
                        target="_blank"
                        title="새창연결"
                        rel="noopener noreferrer"
                      >
                        <i className="ri-twitter-fill" />
                        <span className="sr-only">트위터 공유하기</span>
                      </Link>
                    </li>
                    <li className="list-item instagram">
                      <Link
                        to="#"
                        target="_blank"
                        title="새창연결"
                        rel="noopener noreferrer"
                      >
                        <i className="ri-instagram-fill" />
                        <span className="sr-only">인스타그램 공유하기</span>
                      </Link>
                    </li>
                    <li className="list-item kakaotalk">
                      <Link
                        to="#"
                        target="_blank"
                        title="새창연결"
                        rel="noopener noreferrer"
                      >
                        <i className="ri-kakao-talk-fill" />
                        <span className="sr-only">카카오톡 공유하기</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <button type="button">
                    <i className="ri-printer-line" />
                    <span className="sr-only">print</span>
                  </button>
                </li>
                <li>
                  <button type="button">
                    <i className="ri-links-line" />
                    <span className="sr-only">link copy</span>
                  </button>
                </li>
              </ul>
            </div>
            {/* E::content-util */}
          </div>
          {/* E::content-title */}

          {/* S::content */}
          <div id="content">
            {/* S::sub-tab */}
            <div className="sub-tab">
              <button
                type="button"
                className={`sub-tab__btn ${isSubTab ? 'close' : 'open'}`}
                onClick={subTabToggle}
              >
                <span>선택메뉴명 노출</span>
                <span className="sr-only">{isSubTab ? '닫기' : '열기'}</span>
              </button>
              <ul className={`sub-tab__list ${isSubTab ? 'show' : 'hide'}`}>
                <li className="active">
                  <Link to="#">서브메뉴(3차)</Link>
                </li>
                <li>
                  <Link to="#">서브메뉴(3차)</Link>
                </li>
                <li>
                  <Link to="#">서브메뉴</Link>
                </li>
              </ul>
            </div>
            {/* E::sub-tab */}
            <div className="cont-wrap">{children}</div>
          </div>
          {/* E::content */}
        </section>
        {/* E::content */}
      </div>
      {/* S::content-wrap */}
    </SubContainer>
  );
}

const SubContainer = styled.section`
  /* inner-container Layout */
  .inner-container {
    max-width: var(--cantainer-width);
    margin: 0 auto;
    padding-right: 1.6rem;
    padding-left: 1.6rem;
  }
  /* title-heading */
  h2 {
    color: var(--color-white);
    font-size: 3.8rem;
  }
  /* sub-visual */
  .sub-visual {
    height: 24rem;
    background-repeat: no-repeat;
    background-position: center;
    [class*='__title'] {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: var(--cantainer-width);
      width: 100%;
      margin: 0 auto;
      padding: 0 1.6rem;
      height: calc(100%);
      h2 {
        color: var(--color-white);
        font-family: var(--font-GmarketSans);
        line-height: 1;
        text-transform: uppercase;
        animation: txt-blur 1.5s 0.1s both;
        @-webkit-keyframes txt-blur {
          0% {
            filter: blur(12px);
            opacity: 0;
          }
          100% {
            filter: blur(0);
            opacity: 1;
          }
        }
        @keyframes txt-blur {
          0% {
            filter: blur(12px);
            opacity: 0;
          }
          100% {
            filter: blur(0);
            opacity: 1;
          }
        }
      }
      p {
        color: var(--color-white);
        font-size: 2rem;
        line-height: 1.8;
        opacity: 0;
        animation: txt-Up 0.7s 0.5s both;
        @-webkit-keyframes txt-Up {
          0% {
            visibility: visible;
            opacity: 0;
            transform: translate3d(0, 50%, 0);
            filter: blur(8px);
          }
          100% {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            filter: blur(0);
          }
        }
        @keyframes txt-Up {
          0% {
            visibility: visible;
            opacity: 0;
            transform: translate3d(0, 50%, 0);
            filter: blur(8px);
          }
          100% {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            filter: blur(0);
          }
        }
      }
    }
  }
  /* path */
  .c-path {
    background: var(--color-gray-f5f5f5);
    .inner-container {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    .breadcrumb {
      display: flex;
      align-items: center;
      li {
        position: relative;
        font-size: 1.4rem;
        &.home {
          i {
            font-size: 1.8rem;
          }
        }
        &.active {
          font-weight: 500;
        }
        & + li {
          margin-left: 2.4rem;
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: -2.4rem;
            left: calc(-2.4rem + 0.35rem);
            transform: translateY(-50%);
            display: inline-block;
            width: 1.8rem;
            height: 1.8rem;
            background: url(${ArrowIcon}) no-repeat center / 1.8rem;
          }
        }
      }
    }
  }
  /* content-wrap */
  .content-wrap {
    display: flex;
    gap: 6rem;
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  /* contents */
  .c-content {
    flex: 1;
    min-height: 60rem;
    .c-path {
      margin-bottom: 1rem;
      background-color: transparent;
    }
  }
  /* title */
  .c-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2.4rem;
    margin-bottom: 4rem;
    border-bottom: 1px solid var(--color-gray-d9d9d9);
    h3 {
      font-size: 3.2rem;
    }
  }
  /* content-util */
  .util {
    &__list {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 1rem;
      li {
        position: relative;
        button {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 4rem;
          height: 4rem;
          font-size: 1.9rem;
          border-radius: 100%;
          background-color: var(--color-gray-f5f5f5);
        }
      }
      .share__list {
        position: absolute;
        top: 5rem;
        z-index: 1;
        &.hide {
          display: none;
        }
        > li {
          & + li {
            margin-top: 0.5rem;
          }
          a {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 4rem;
            height: 4rem;
            color: var(--color-white);
            font-size: 2rem;
            border-radius: 100%;
          }
          &.facebook {
            a {
              background: var(--color-facebook);
            }
          }
          &.twitter {
            a {
              background: var(--color-twitter);
            }
          }
          &.kakaotalk {
            a {
              background: var(--color-kakaotalk);
            }
          }
          &.instagram {
            a {
              background: var(--color-instagram);
            }
          }
        }
      }
    }
  }
  /* 서브 tab menu */
  .sub-tab {
    position: relative;
    margin-bottom: 4.8rem;
    z-index: 1;
    &__list {
      li {
        &.active {
          a {
            color: var(--color-white);
            border-color: var(--color-main);
            background: var(--color-main);
            span {
              &::after {
                width: 100%;
              }
            }
            &:hover {
              color: var(--color-white);
              border-color: var(--color-main);
            }
          }
        }
        a {
          display: block;
          border: 1px solid var(--color-gray-eaeaea);
          transition: all 0.3s ease 0s;
          background: var(--color-white);
          &:hover {
            color: var(--color-black);
            border-color: var(--color-gray-c8c8c8);
            span {
              &::after {
                width: 100%;
              }
            }
          }
          span {
            position: relative;
            display: inline-block;
            padding: 0 2.4rem;
            &::after {
              content: '';
              position: absolute;
              left: 0;
              bottom: 0;
              display: block;
              width: 0;
              height: 2px;
              background: var(--color-main);
            }
          }
        }
      }
    }
  }
  /* 1201px ~ */
  @media ${({ theme }) => theme.media.xlMin} {
    /* aside - lnb(web) */
    .c-lnb {
      flex: 0 0 17.2%;
      h2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3.2rem;
        height: 12rem;
        color: var(--color-white);
        background: var(--color-main);
      }
      /* lnb */
      .lnb {
        /* 1차메뉴 */
        .list--depth1 {
          > li {
            &.active {
              > a {
                color: var(--color-main);
              }
            }
            &.has-sub {
              > a {
                &::after {
                  content: '';
                  display: inline-block;
                  position: absolute;
                  top: 50%;
                  right: 0;
                  transform: translateY(-50%);
                  width: 1rem;
                  height: 2px;
                  background: var(--color-gray-969696);
                  transition: all 0.3s ease 0s;
                }
              }
              &:not(.active) {
                > a {
                  &::before {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    top: 50%;
                    right: 0.5rem;
                    transform: translate(calc(-50% + 2px), -50%);
                    width: 2px;
                    height: 10px;
                    transition: all 0.3s ease 0s;
                    background: var(--color-gray-969696);
                  }
                }
                .depth2 {
                  display: none;
                }
              }
              &.active {
                > a {
                  &::after {
                    background: var(--color-black);
                  }
                }
              }
            }
            > a {
              position: relative;
              display: block;
              padding: 1.6rem 0;
              font-size: 1.7rem;
              color: var(--color-black);
              font-weight: 500;
              border-bottom: 1px solid var(--color-gray-d9d9d9);
              transition: all 0.3s ease;
            }
          }
        }
        /* 2차메뉴 */
        .depth2 {
          padding: 0.8rem 1.6rem;
          background-color: var(--color-gray-f5f5f5);
          .list--depth2 {
            > li {
              margin: 0.8rem 0;
              &.active {
                > a {
                  color: var(--color-main);
                }
              }
            }
          }
        }
      }
    }
  }
  /* ~ 1200px */
  @media ${({ theme }) => theme.media.xl} {
    .c-lnb {
      display: none;
    }
  }
  /* 769px ~ */
  @media ${({ theme }) => theme.media.mdMin} {
    .sub-tab {
      &__list {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 1rem;
        li {
          &.active {
            a {
              border-radius: 0;
            }
          }
          a {
            padding: 1.2rem 2rem;
            border-radius: 0.6rem;
            &:hover {
              border-radius: 0;
            }
          }
        }
      }
      &__btn {
        display: none;
      }
    }
  }
  /* ~ 768px */
  @media ${({ theme }) => theme.media.md} {
    .sub-tab {
      &__list {
        &.hide {
          visibility: hidden;
          opacity: 0;
        }
        position: absolute;
        left: 0;
        right: 0;
        display: block;
        visibility: visible;
        opacity: 1;
        transition-property: visibility, opacity;
        transition-duration: 250ms;
        transition-timing-function: ease-in-out;
        li {
          a {
            position: relative;
            padding: 0.8rem 2rem;
            margin: -1px 0 0 0;
            &:hover {
              border-color: var(--color-gray-c8c8c8);
              z-index: 1;
            }
          }
        }
      }
      &__btn {
        width: 100%;
        padding: 1.2rem 2rem;
        font-size: 1.5rem;
        text-align: left;
      }
    }
  }
`;

SubAsideContainerVeiw.propTypes = {
  children: PropTypes.element,
  subVisual: PropTypes.string,
};

SubAsideContainerVeiw.defaultProps = {
  children: '',
  subVisual: '',
};

export default SubAsideContainerVeiw;
