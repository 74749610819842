/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import styled from 'styled-components';

// import SubContainer from '../../atoms/SubContainer';
// import SubWrapContainer from '../../atoms/SubWrapContainer';
import SubAsideContainer from '../../atoms/SubAsideContainer';
import Button from '../../atoms/Button';
import SubVisualImg from '../../../assets/img/product/sub-visual.jpg';
import JoinCompleteIcon from '../../atoms/Icon/JoinCompleteIcon';

function JoinCompleteView() {
  return (
    <StyledSubContainer
      isVisible
      title="Member"
      subTxt=" 홈페이지에 오신 것을 환영합니다."
      subVisual={SubVisualImg}
      className="sub-member"
    >
      <article className="c-join-complete">
        <h3>회원가입</h3>
        <ol className="agree-step">
          <li>
            <span>Step1</span>
            <strong>약관동의</strong>
          </li>
          <li>
            <span>Step2</span>
            <strong>본인 인증</strong>
          </li>
          <li>
            <span>Step3</span>
            <strong>회원정보 입력</strong>
          </li>
          <li className="active">
            <span>Step4</span>
            <strong>가입 완료</strong>
          </li>
        </ol>
        <h4>가입 완료</h4>
        <div className="join-complete">
          <div className="join-complete__txt">
            <JoinCompleteIcon />
            <p>
              회원가입이 완료되었습니다.
              <br />
              서비스를 이용하시려면 로그인하시기 바랍니다.
            </p>
          </div>
          <div className="join-complete__btn">
            <Button href="#" variant="default">
              로그인하기
            </Button>
            <Button href="#">메인으로</Button>
          </div>
        </div>
      </article>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled(SubAsideContainer)`
  h3,
  .info-txt {
    text-align: center;
  }
  h4 {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  .agree-step {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 4rem;
    > li {
      position: relative;
      counter-increment: stepNum;
      flex: 1;
      text-align: center;
      color: #ff6347;
      &::before {
        content: '✓';
        display: block;
        margin: 0 auto 4px;
        width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        font-weight: bold;
        border: 2px solid #ff6347;
        border-radius: 50%;
        background-color: #fff;
      }
      &:after {
        content: '';
        height: 2px;
        width: 100%;
        background-color: #ff6347;
        position: absolute;
        top: 20px;
        left: 50%;
        z-index: -1;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      &.active {
        &:before {
          content: counter(stepNum);
          font-family: inherit;
          font-weight: 700;
          color: #fff;
          background-color: #ff6347;
          border-color: #ff6347;
        }
        &:after {
          background-color: #ededed;
        }
        ~ li {
          color: #808080;
          &:before {
            content: counter(stepNum);
            font-family: inherit;
            font-weight: 700;
            background-color: #ededed;
            border-color: #ededed;
          }
          &:after {
            background-color: #ededed;
          }
        }
      }
      span {
        text-transform: uppercase;
      }
      strong {
        display: block;
        font-weight: 500;
      }
    }
  }
  .join-complete {
    padding: 5rem 3.2rem 6rem;
    border: 1px solid var(--color-gray-d9d9d9);
    &__txt {
      text-align: center;
      svg {
        max-width: 30rem;
        height: auto;
      }
      p {
        font-size: 1.9rem;
      }
    }
    &__btn {
      display: flex;
      justify-content: center;
      gap: 1rem;
      margin-top: 5.4rem;
    }
  }
`;

export default JoinCompleteView;
