import { useCallback } from 'react';
import { v4 } from 'uuid';

export const getFileData = originFiles => {
  if (!originFiles) return [];
  let files = originFiles;
  if (typeof originFiles === 'string') files = [originFiles];
  const fileData = files.map(file => ({
    uid: v4(),
    name: file,
    status: 'done',
    url: 'url',
  }));
  return fileData;
};

/**
 * id, parentId 추가
 * @param {*} data
 * @returns
 */
export const convertTreeProperty = data => {
  return data.map(item => ({
    ...item,
    id: item.menuNo,
    parent_id: item.upperMenuNo,
  }));
};

// 메뉴 object return;
export const getTreeMenu = (curMenues, targetId) => {
  const menu = curMenues.find(
    m => m.menuUrl.indexOf(targetId) > 0 || m.menuNo === targetId,
  );

  if (menu) return menu;

  // 다음 자식의 Menu들을 찾고, undefined는 삭제
  const nextMenues = curMenues.flatMap(m => m.children).filter(c => c);

  // 재귀적으로 메뉴를 찾는다.

  return getTreeMenu(nextMenues, targetId);
};

/**
 * 리스트에서 트리를 반환하기 위한 함수
 * @param {*} list 목록
 * @param {*} parentProperty 부모가 될 속성 값
 * @param {*} property 자식이 될 속성 값
 * @returns 트리를 반환
 */
export const listToTree = (
  list,
  parentProperty = 'upperMenuNo',
  property = 'menuNo',
) => {
  const tree = [];
  const lookUp = {};

  /**
   * 초기 기본 구조를 생성
   * depth 지정
   */
  list.forEach(item => {
    lookUp[item[property]] = { ...item, children: [], depth: 1 };
  });

  /**
   * 위에 생성된 lookUp 객체를 이용하여
   * 트리 구조의 배열을 생성
   */
  list.forEach(item => {
    // 부모 요소 정보를 확인
    const upperNo = item[parentProperty];

    // 부모 값이 없는 경우 또는 부모 오브젝트 속성이 없는 경우
    if (upperNo === null || !lookUp[upperNo]) {
      tree.push(lookUp[item[property]]);
    } else {
      // 부모 속성을 찾아 자식 객체를 저장
      lookUp[upperNo].children.push(lookUp[item[property]]);
      // 부모 객체의 depth에 자식 객체에서 +1을하여 뎁스를 저장
      lookUp[item[property]].depth = lookUp[upperNo].depth + 1;
    }
  });

  return tree;
};

// 배열 구조를 재 생성
// 1: [][][]
// 2: [][][]
// 3: [][][]
export const selectedLeafStructure = arr => {
  const tmpObj = {};

  const tree = tr => {
    if (!tmpObj[tr.depth]) tmpObj[tr.depth] = [];
    tmpObj[tr.depth].push(tr);

    if (tr.children.length > 0) return array(tr.children);
  };

  const array = arr => {
    if (arr.length > 0) {
      arr.forEach(a => tree(a));
    }
  };

  array(arr);

  return tmpObj;
};

/**
 * tree -> array
 * [
 *  {
 *    menuNo: {menu}
 *  }
 * ]
 * @param {*} _treeObject tree 오브젝트
 * @returns array
 */
export const flattenTree = _treeObject => {
  const result = {};

  _treeObject.forEach(node => {
    result[node.menuNo] = node;

    if (node.children.length > 0) {
      const childrenResult = flattenTree(node.children);
      Object.assign(result, childrenResult);
    }
  });

  return result;
};
