import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SiteLayoutView from './SiteLayoutView';

function SiteLayout({ children }) {
  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenMenu = bool => {
    setOpenMenu(bool);
  };

  const props = { children, openMenu, onOpenMenu: handleOpenMenu };
  return <SiteLayoutView {...props} />;
}

SiteLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default SiteLayout;
