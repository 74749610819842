import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SiteHeader from '../SiteHeader';
import SiteFooter from '../SiteFooter';

function SiteLayoutView(props) {
  const { openMenu, children } = props;

  return (
    <SiteLayout className={`site-layout ${openMenu ? 'open' : ''}`}>
      <SiteHeader {...props} />
      <SiteContent {...props}>{children}</SiteContent>
      <SiteFooter />
    </SiteLayout>
  );
}

const SiteLayout = styled.div`
  min-height: 100vh;
  &.open {
    height: 100vh;
    /* background: var(--color-black-rgba-50); */
  }
`;
const SiteContent = styled.main`
  /* padding-top: 7.2rem; */
`;

SiteLayoutView.propTypes = {
  openMenu: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};

export default SiteLayoutView;
