import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function MenuView(props) {
  const { menuToTree, menuesToTree, menuSelect } = props;

  const ListItem = (menuesToTree, depth) => {
    const [draw, setDraw] = useState(false);
    const [current, setCurrent] = useState(999);

    return menuesToTree?.map((item, index) => {
      return (
        <li
          className={`list-item${
            item.menuNo === menuToTree[depth]?.menuNo ? ' active' : ''
          }`}
          data-seq={index}
        >
          <Link
            to="#"
            onMouseOver={() => {
              setDraw(true);
              setCurrent(index);
            }}
            onKeyUp={e => {
              if (e.key === 'Tab') {
                e.preventDefault();
                setDraw(true);
                setCurrent(index);
              }
            }}
            onClick={e => {
              e.preventDefault();
              menuSelect(item.menuNo);
            }}
          >
            <span>{item.menuNm}</span>
          </Link>
          {item.children?.length > 0 && (
            <div
              className={
                current === index
                  ? `depth${depth + 1}${
                      item.menuNo === menuToTree[depth]?.menuNo ? ' open' : ''
                    }`
                  : `depth${depth + 1}`
              }
            >
              <ul className={`list--depth${depth + 1}`}>
                {ListItem(item.children, depth + 1)}
              </ul>
            </div>
          )}
        </li>
      );
    });
  };

  return (
    menuesToTree?.length > 0 && (
      <ul className="gnb-list list--depth1">{ListItem(menuesToTree, 1)}</ul>
    )
  );
}

MenuView.propTypes = {
  menuToTree: PropTypes.objectOf(PropTypes.oneOf([PropTypes.object])),
  menuesToTree: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOf([PropTypes.object])),
  ),
  menuSelect: PropTypes.func,
};

MenuView.defaultProps = {
  menuToTree: {},
  menuesToTree: [],
  menuSelect: () => {},
};

export default MenuView;
