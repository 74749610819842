import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

/**
 * Footer
 * @returns
 */
function SiteFooterView() {
  // scrollToTop
  const [showButton, setShowButton] = useState(false);
  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleShowButton = () => {
      if (window.scrollY > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.addEventListener('scroll', handleShowButton);
    return () => {
      window.removeEventListener('scroll', handleShowButton);
    };
  }, []);
  return (
    <StyledFooter className="site-layout__footer">
      <div className="c-footer">
        <div className="c-footer__nav">
          <ul>
            <li>
              <Link to="#">하단메뉴1</Link>
            </li>
            <li>
              <Link to="#">하단메뉴2</Link>
            </li>
            <li>
              <Link to="#">하단메뉴3</Link>
            </li>
            <li>
              <Link to="#">하단메뉴4</Link>
            </li>
            <li>
              <Link to="#">하단메뉴5</Link>
            </li>
          </ul>
        </div>
        <div className="c-footer__info">
          <a href="tel:1800-2398">T. 1800-2398</a>
          <address>
            A. 경기 성남시 분당구 성남대로 295 (정자동, 대림아크로텔) C동 218호
          </address>
          <p className="copy">Copyright © Baneung Inc. All rights reserved.</p>
        </div>
        {showButton && (
          <div className="scroll-top">
            <button type="button" id="top" onClick={scrollToTop}>
              <i className="ri-arrow-up-line" />
              <span className="sr-only">상단으로 바로가기</span>
            </button>
          </div>
        )}
      </div>
    </StyledFooter>
  );
}

const StyledFooter = styled.footer`
  background: var(--color-gray-323232);
  .c-footer {
    position: relative;
    max-width: var(--cantainer-width);
    margin: 0 auto;
    padding: 2.4rem;
    &__nav {
      border-bottom: 1px solid var(--color-white-rgba-10);
      padding-bottom: 1.6rem;
      ul {
        li {
          display: inline-block;
          & + li {
            margin-left: 2.4rem;
          }
          a {
            color: var(--color-white);
          }
        }
      }
    }
    &__info {
      padding-top: 2.4rem;
      color: var(--color-gray-969696);
      a {
        position: relative;
        color: var(--color-white-rgba-85);
        font-family: var(--font-Roboto);
        margin-right: 2rem;
        &::before {
          content: '';
          position: absolute;
          bottom: -2px;
          left: 0;
          display: inline-block;
          width: 0;
          height: 1px;
          background: var(--color-white-rgba-50);
          transition: all 0.3s;
        }
        &:hover {
          color: var(--color-white);
          &::before {
            width: 100%;
          }
        }
      }
      .copy {
        margin-top: 0.5rem;
        color: var(--color-white-rgba-25);
        font-size: 1.2rem;
        font-family: var(--font-GmarketSans);
      }
    }
  }
  .scroll-top {
    position: fixed;
    animation: scroll-Up 0.4s both;
    @-webkit-keyframes scroll-Up {
      0% {
        visibility: visible;
        opacity: 0;
        transform: translate3d(0, 30%, 0);
      }
      100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
    @keyframes scroll-Up {
      0% {
        visibility: visible;
        opacity: 0;
        transform: translate3d(0, 30%, 0);
      }
      100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
    button {
      width: 6rem;
      height: 6rem;
      border-radius: 50%;
      background: var(--color-main);
      background: var(--color-gray-3c3c3c);
      box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.2);
      transition: all 0.3s;
      i {
        color: var(--color-white);
        font-size: 2.6rem;
      }
    }
  }
  @media ${({ theme }) => theme.media.mdMin} {
    .c-footer {
      .scroll-top {
        right: 3rem;
        bottom: 3rem;
      }
    }
  }
  @media ${({ theme }) => theme.media.md} {
    .c-footer {
      .scroll-top {
        right: 2rem;
        bottom: 3rem;
        z-index: 1;
      }
    }
  }
`;

export default SiteFooterView;
