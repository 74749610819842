import Main from '../../components/pages/Main';
import AboutUs from '../../components/pages/AboutUs';
import Playground from '../../components/pages/Playground';
import OurWork from '../../components/pages/OurWork';
import Notice from '../../components/pages/Notice';
import NoticeDetail from '../../components/pages/NoticeDetail';
import ContactUs from '../../components/pages/ContactUs';
import Employment from '../../components/pages/Employment';
import Location from '../../components/pages/Location';
import Chat from '../../components/pages/Chat';

import SubLayoutPublish from '../../components/pages/SubLayoutPublish';
import SubLayoutPublish2 from '../../components/pages/SubLayoutPublish2';
import Gallery from '../../components/pages/Gallery';
import Board from '../../components/pages/Board';
import Faq from '../../components/pages/Faq';
import Login from '../../components/pages/Login';
import JoinAgree from '../../components/pages/JoinAgree';
import JoinCertify from '../../components/pages/JoinCertify';
import JoinRegist from '../../components/pages/JoinRegist';
import JoinComplete from '../../components/pages/JoinComplete';
import Search from '../../components/pages/Search';
import Community from '../../components/pages/Community';
import CommunityCafe from '../../components/pages/CommunityCafe';
import Contents from '../../components/pages/Contents';
import Survey from '../../components/pages/Survey';
import SurveyRegist from '../../components/pages/SurveyRegist';
import SurveyResult from '../../components/pages/SurveyResult';

import Image from '../../components/pages/Draft/image';
import Sub from '../../components/pages/Draft/sub';
import Video from '../../components/pages/Draft/video';

const Routes = [
  {
    element: Main,
    path: '/main',
  },
  // subLayoutPublish
  {
    element: SubLayoutPublish,
    path: '/sub',
  },
  // subLayoutPublish
  {
    element: SubLayoutPublish2,
    path: '/sub2',
  },
  // about
  // about > 회사소개
  {
    element: AboutUs,
    path: '/aboutUs',
  },
  // product
  // product > playground
  {
    element: Playground,
    path: '/playground',
  },
  // our work
  {
    element: OurWork,
    path: '/ourWork',
  },
  // support
  // support > 공지사항
  {
    element: Notice,
    path: '/notice',
  },
  // support > 공지사항상세
  {
    element: NoticeDetail,
    path: '/notice/detail',
  },
  // support > 공지사항상세
  {
    element: NoticeDetail,
    path: '/notice/detail/:noticeid',
  },
  // support > 문의하기
  {
    element: ContactUs,
    path: '/contactUs',
  },
  // support > 채용안내
  {
    element: Employment,
    path: '/employment',
  },
  // support > 오시는길
  {
    element: Location,
    path: '/location',
  },
  // 채팅
  {
    element: Chat,
    path: '/chat',
  },
  // 채팅 확인 용
  {
    element: Chat,
    path: '/chat/:roomId',
  },
  // 일반(기본테이블) 게시판
  {
    element: Board,
    path: '/board',
  },
  // 갤러리형 게시판
  {
    element: Gallery,
    path: '/gallery',
  },
  // FAQ 게시판
  {
    element: Faq,
    path: '/faq',
  },
  // 로그인
  {
    element: Login,
    path: '/login',
  },
  // 로그인 - 약관동의
  {
    element: JoinAgree,
    path: '/member/joinAgree',
  },
  // 로그인 - 본인인증
  {
    element: JoinCertify,
    path: '/member/joinCertify',
  },
  // 로그인 - 회원정보 입력
  {
    element: JoinRegist,
    path: '/member/joinRegist',
  },
  // 로그인 - 가입완료
  {
    element: JoinComplete,
    path: '/member/joinComplete',
  },
  // 통합검색
  {
    element: Search,
    path: '/search',
  },
  // 커뮤니티
  {
    element: Community,
    path: '/community',
  },
  // 커뮤니티 - 커뮤니티홈
  {
    element: CommunityCafe,
    path: '/community/communityCafe',
  },
  // 설문조사
  {
    element: Survey,
    path: '/survey',
  },
  // 설문조사 - 등록(참여)
  {
    element: SurveyRegist,
    path: '/survey/surveyRegist',
  },
  // 설문조사 - 결과
  {
    element: SurveyResult,
    path: '/survey/surveyResult',
  },

  {
    element: Image,
    path: '/draftImage',
    isNoneLayout: true,
  },
  {
    element: Video,
    path: '/draftVideo',
    isNoneLayout: true,
  },
  {
    element: Sub,
    path: '/draftSub',
    isNoneLayout: true,
  },
  {
    element: Contents,
    path: `/contents/:id`,
  },
];

export default Routes;
