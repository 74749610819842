import React from 'react';
import SiteHeaderView from './SiteHeaderView';
import useMenu from '../../../hooks/useMenu';

function SiteHeader(props) {
  const { menuToTree, menues, menuesToTree, menuSelect } = useMenu();

  const newProps = {
    ...props,
    menuToTree,
    menues,
    menuesToTree,
    menuSelect,
  };
  return <SiteHeaderView {...newProps} />;
}

export default SiteHeader;
